import React from 'react'
import PropTypes from 'prop-types'
import mime from 'mime-types'
import classnames from 'classnames'

import { utils, image } from 'services'
import { getThumbnailSize } from 'services/utils'

const defaultImage = image('not-available.png')

export const ImagePicture = ({
  src,
  originalImage,
  className,
  width,
  height,
  responsiveImage = [],
  ...rest
}) => {
  const mimeType = mime.lookup(src)

  const handleError = event => {
    if (src) {
      // originalImage param is used to identify the image that was cropped
      // and is only used by PictureCropped and PictureThumbnail components

      console.error(
        `[IMAGE_NOT_FOUND] src:${src} | originalImage:${originalImage}`,
      )
    }
  }

  return (
    <picture onError={handleError}>
      <source srcSet={src} type={mimeType} />
      {responsiveImage.map(({ src, mediaQuerySize }) => (
        <source
          key={src}
          srcSet={src}
          media={`(max-width: ${mediaQuerySize}px)`}
        />
      ))}
      <img
        {...rest}
        width={width}
        height={height}
        loading="lazy"
        className={classnames('lazy', className)}
        data-srcset={src}
      />
    </picture>
  )
}

export const PictureCropped = ({ image, width, height, format, ...rest }) => {
  const pictureCropped = image || defaultImage

  const imageSizeFormat = utils.getCroppedImage({
    file: pictureCropped,
    width,
    height,
    format,
  })

  return (
    <ImagePicture
      src={imageSizeFormat}
      originalImage={image}
      width={width}
      height={height}
      {...rest}
    />
  )
}

export const PictureThumbnail = ({ image, size, format, ...rest }) => {
  const pictureThumbnail = image || defaultImage

  const imageSizeFormat = utils.getThumbnail({file: pictureThumbnail, sizeName: size, format})
  const { width, height } = getThumbnailSize(size)

  return (
    <ImagePicture
      src={imageSizeFormat}
      originalImage={pictureThumbnail}
      width={width}
      height={height}
      {...rest}
    />
  )
}

PictureCropped.propTypes = {
  image: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  format: PropTypes.string,
}

PictureThumbnail.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string,
  format: PropTypes.string,
}
