import css from 'styled-jsx/css'
import { colors, measurements } from 'theme'

export default css`

 .notificationSuccess p{
    margin: 0;
    color: ${colors.white};
    padding: .5rem;
  }
  .notificationSuccess{
    background:${colors.primary};
    display: flex;
    color: ${colors.white};
    align-items: center;
    padding: .5rem;
  }
`

export const simpleAlertStyles = css`
  .alert {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.2rem;
    margin-bottom: 1rem;
    border-radius: ${measurements.borderRadius};
  }

  .alert.left {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .alert.rigth {
    justify-content: flex-end;
    align-items: flex-end;
    text-align: rigth;
  }

  .alert.danger {
    background-color: ${colors.alpha(colors.dangerLow, 0.3)};
  }

  .alert.purple {
    background-color: ${colors.alpha(colors.purple, 0.3)};
  }
  
  .alert.blue {
    background-color: ${colors.alpha(colors.highlightBlue, 0.3)};
  }

  .alert :global(.alert-icon) {
    margin-right: 0.5rem;
    color: ${colors.dangerLow};
  }

  .alert.purple :global(.alert-icon) {
    color: ${colors.purple};
  }
  
  .alert.blue :global(.alert-icon) {
    color: ${colors.highlightBlue};
  }
`
