import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ContactJourneyCreators } from 'app/reducers/ContactJourneyRedux'
import { getSessionData } from 'app/selectors'

import { NavBarMenuDesktop } from './NavBarMenus'

import getNavBarProps from './getNavBarProps'

const SCROLL_TOLERANCE = 0

const NavBarMobileMenuDynamic = dynamic(() => import('./NavBarHamburgerMenu'), {
  ssr: false,
})

class NavigationBar extends PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    whiteNavBar: PropTypes.bool,
    fixed: PropTypes.bool
  }

  static defaultProps = {
    whiteNavBar: false,
    fixed: true
  }

  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
      fixed: false,
      negativeMenu: false,
      burgerMenuOpen: true,
      showNavBarMobileMenu: false
    }
  }

  componentDidMount() {
    const { whiteNavBar, fixed } = this.props

    this.setState({ fixed })

    if (!whiteNavBar) {
      window.addEventListener('scroll', this.onScroll)
      this.onScroll()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = e => {
    if (window.scrollY > SCROLL_TOLERANCE && !this.negative) {
      this.setState(
        {
          negativeMenu: true
        },
        () => {
          this.negative = true
        }
      )
    } else if (window.scrollY <= SCROLL_TOLERANCE && this.negative) {
      this.setState(
        {
          negativeMenu: false
        },
        () => {
          this.negative = false
        }
      )
    }
  }

  closeMenu = ({ isOpen }) => this.setState({ burgerMenuOpen: isOpen })

  render() {
    const { desktopNavBarProps, mobileNavBarProps } = getNavBarProps(
      this.props,
      this.state
    )

    if (this.state.showNavBarMobileMenu) {
      return (
        <NavBarMobileMenuDynamic
          {...mobileNavBarProps}
          handleCloseMenu={this.closeMenu}
        />
      )
    }

    return (
      <NavBarMenuDesktop
        {...desktopNavBarProps}
        overFixed={this.props.fixed}
        handleShowNavBarMobileMenu={() =>
          this.setState({ showNavBarMobileMenu: true })
        }
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendGTEvent: ContactJourneyCreators.sendGTEvent
    },
    dispatch
  )
})

const mapStateToProps = state => {
  return {
    isAuthenticated: getSessionData(state).isAuthenticated
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBar)
