import css from 'styled-jsx/css'
import { colors, measurements } from 'theme'

export default css`
  .wrapper-address {
    /* background-color: ${colors.white}; */
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    border-radius: ${measurements.borderRadius};
  }
  .address-for-search {
    font-size: 1rem;
  }
  .tag-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${colors.border};
    margin: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: ${measurements.borderRadius};
    background-color: ${colors.white};
  }
  .address {
    display: flex; 
    flex-direction: row;
    align-items: center;
  }
  .columns{
    display:flex
  }
  .columns .field{
    margin: 0 .5rem!important;
  }
  .location-filters .neighborhood-dropdown-wrapper {
    margin: 1rem 0 0.5rem 0;
  }
  .location-filters .neighborhood-dropdown-wrapper :global(.neighborhood-dropdown .react-selectize-search-field-and-selected-values){
    min-height: 2rem;
  }
  .location-filters .neighborhood-dropdown-wrapper :global(.neighborhood-dropdown .react-selectize-toggle-button-container){
    height: 2rem;
    line-height: 2rem;
  }
  .location-filters .neighborhood-dropdown-wrapper :global(.neighborhood-dropdown .item){
    line-height: 2rem;
  }
  .location-filters .neighborhood-dropdown-wrapper :global(.neighborhood-dropdown .react-selectize-control .react-selectize-placeholder){
    line-height: 2rem;
  }
`
