/**
 * calculateOffset
 * @param {Element} container - DOM element that contains Carousel
 * @param {Element} carousel - List of Carousel elements 
 * @param {Number} offset - Number of px that should be carouse move
 * @param {Number} currentTranslated - Current px that Carousel was moved
 * @returns {Number} - Next translated position 
 */
const calculateOffset = (
  container,
  carousel,
  offset,
  currentTranslated
) => {
  const Container = container.getBoundingClientRect()
  const Carousel = carousel.getBoundingClientRect()

  const proposalOffset = currentTranslated - offset

  const nextCarouselPosition = Carousel.left + offset * -1
  const nextCarouselPositionRightCorner = nextCarouselPosition + Carousel.width

  if (Container.width > Carousel.width) {
    return 0
  }

  if (nextCarouselPosition >= Container.left) {
    return Container.left - Carousel.left + currentTranslated
  }

  if (nextCarouselPositionRightCorner <= Container.right) {
    return Container.right - Carousel.right + currentTranslated
  }

  return proposalOffset
}

export default calculateOffset