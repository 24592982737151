import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { bindActionCreators } from 'redux'

import { BinActionCreators } from 'app/reducers/BinRedux'
import { store } from 'services'
import { normalizeAsUrl } from 'services/string'
import { SimpleDropDown, DropDownSelectize } from 'controls/DropDown'
import { SectionControlFilter } from 'components/PropertyFilters'

import style from 'components/Bin/Filter/styles'
import locationStyle from './style'

class BinFilterLocation extends Component {
  static propTypes = {
    filters: PropTypes.shape({
      propertyType: PropTypes.string,
      operationType: PropTypes.string,
      locations: PropTypes.array,
      age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bedrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bathrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      halfBathrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      parkingSpaces: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabledSelect: PropTypes.bool,
      selectedLocations: PropTypes.array,
      multipleNeighborhood: PropTypes.bool,
    }).isRequired,
    onLocationsChange: PropTypes.func.isRequired,
  }

  state = {
    currentStateId: undefined,
    currentMunicipalityId: undefined,
    currentNeighborhoodId: undefined,
    currentNeighborhoodSelected: [],
  }

  componentDidMount() {
    this.props.actions.getStates()
  }

  componentDidUpdate(prevProps) {
    const {
      filters: { selectedLocations },
    } = prevProps

    if (
      selectedLocations.length > 0 &&
      this.props.filters.selectedLocations.length === 0
    ) {
      this.setState({
        currentStateId: undefined,
        currentMunicipalityId: undefined,
        currentNeighborhoodId: undefined,
        currentNeighborhoodSelected: [],
      })
      this.props.actions.clearMunicipalities()
      this.props.actions.clearNeighborhoods()
    }
  }

  UNSAFE_componentWillReceiveProps(nexProps) {
    const {
      filters: { selectedLocations },
      actions: { getMunicipalities, getNeighborhoods, updateLocations },
    } = nexProps
    const {
      stateOptions,
      municipalitiesOptions,
      neighborhoodsOptions,
    } = nexProps

    const {
      currentStateId,
      currentMunicipalityId,
      currentNeighborhoodId,
    } = this.state

    if (currentStateId && currentMunicipalityId && currentNeighborhoodId) {
      return
    }

    const location =
      selectedLocations && selectedLocations[0]
        ? selectedLocations[0]
            .split('::')
            .map(v => v.replace(/-/gi, ' '))
            .reverse()
        : []

    if (!currentStateId && location[0] && stateOptions.length > 0) {
      const normalizedLocation = normalizeAsUrl(location[0])
      const findState = stateOptions.find(
        ({ label }) => normalizeAsUrl(label) === normalizedLocation,
      )

      findState &&
        this.setState({ currentStateId: findState.id }, () => {
          updateLocations(findState)
          getMunicipalities(findState.id)
        })
    } else if (
      currentStateId &&
      !currentMunicipalityId &&
      location[1] &&
      municipalitiesOptions.length > 0
    ) {
      const normalizedLocation = normalizeAsUrl(location[1])
      const findMunicipality = municipalitiesOptions.find(
        ({ label }) => normalizeAsUrl(label) === normalizedLocation,
      )

      findMunicipality &&
        this.setState({ currentMunicipalityId: findMunicipality.id }, () => {
          updateLocations(findMunicipality)
          getNeighborhoods(currentStateId, findMunicipality.id)
        })
    } else if (
      !currentNeighborhoodId &&
      location[2] &&
      neighborhoodsOptions.length > 0
    ) {
      const normalizedLocation = normalizeAsUrl(location[2])
      if (!this.props.multipleNeighborhood) {
        const findNeighborhood = neighborhoodsOptions.find(({ label }) =>
          normalizeAsUrl(label).includes(normalizedLocation),
        )

        findNeighborhood &&
          this.setState({ currentNeighborhoodId: findNeighborhood.id }, () =>
            updateLocations(findNeighborhood),
          )
      } else {
        const neighborhood = Array.from(
          new Set(
            selectedLocations
              .map(location => location.split('::'))
              .map(location => {
                const [postalCode, neighborhood, municipality, state] = location
                return normalizeAsUrl(neighborhood)
              }),
          ),
        )
          .map(neighborhood => {
            const findNeighborhood = neighborhoodsOptions.find(({ label }) =>
              normalizeAsUrl(label).includes(neighborhood),
            )
            return findNeighborhood
          })
          .filter(n => !!n)
        neighborhood &&
          this.setState({ currentNeighborhoodSelected: neighborhood })
      }
    }
  }

  render() {
    const {
      title = 'Ubicación',
      stateOptions,
      municipalitiesOptions,
      neighborhoodsOptions,
      disabledSelect,
      multipleNeighborhood,
    } = this.props
    const {
      currentStateId,
      currentMunicipalityId,
      currentNeighborhoodId,
    } = this.state

    return (
      <SectionControlFilter title={title}>
        <div
          className={classnames('wrapper-field location-filters', {
            columns: this.props.columns,
          })}
        >
          <SimpleDropDown
            disabled={disabledSelect}
            name="state"
            placeholder="Selecciona estado"
            value={currentStateId || ''}
            onChange={({ target }) => {
              this.setState({ currentStateId: target.value })
              this.props.actions.getMunicipalities(target.value)

              const state = stateOptions.find(so => so.value === target.value)
              this.props.onLocationsChange(`::::::${state.name}`)

              const { label, value, ...stateData } = state
              this.props.actions.updateLocations({ id: value, ...stateData })
            }}
          >
            {stateOptions.map(({ value, label }, i) => (
              <option value={value} key={i}>
                {label}
              </option>
            ))}
          </SimpleDropDown>
          <SimpleDropDown
            disabled={disabledSelect || !municipalitiesOptions.length}
            placeholder="Selecciona delegación o municipio"
            name="municipality"
            value={currentMunicipalityId || ''}
            onChange={({ target }) => {
              this.setState({ currentMunicipalityId: target.value })
              this.props.actions.getNeighborhoods(
                this.state.currentStateId,
                target.value,
              )
              const state = stateOptions.find(
                so => so.value === this.state.currentStateId,
              )
              const municipality = municipalitiesOptions.find(
                so => so.value === target.value,
              )
              this.props.onLocationsChange([
                `::::${municipality.name}::${state.name}`,
              ])

              const { label, value, ...municipalityData } = municipality
              this.props.actions.updateLocations({
                id: value,
                ...municipalityData,
              })
            }}
          >
            {municipalitiesOptions.map(({ value, label }, i) => (
              <option value={value} key={i}>
                {label}
              </option>
            ))}
          </SimpleDropDown>
          {multipleNeighborhood && (
            <div className='neighborhood-dropdown-wrapper'>
              <DropDownSelectize
                multiple
                disabled={disabledSelect || !neighborhoodsOptions.length}
                name="neighborhood"
                placeholder="Selecciona colonia o barrio"
                className="neighborhood-dropdown"
                options={neighborhoodsOptions}
                values={this.state.currentNeighborhoodSelected || ''}
                onValuesChange={values => {
                  const neighborhoodSelected = Array.from(values).map((o, i) => {
                    const neighborhood = neighborhoodsOptions.find(
                      so => so.value === o.value,
                    )
                    const state = stateOptions.find(
                      so => so.value === neighborhood.stateId,
                    )
                    const municipality = municipalitiesOptions.find(
                      so => so.value === neighborhood.municipalityId,
                    )
                    const { label, value, ...neighborhoodData } = neighborhood
                    this.props.actions.updateLocations({
                      id: value,
                      ...neighborhoodData,
                    })

                    return `${neighborhood.postalCode}::${neighborhood.name}::${municipality.name}::${state.name}`
                  })
                  this.props.onLocationsChange(neighborhoodSelected)

                  this.setState({
                    currentNeighborhoodSelected: values,
                  })
                }}
              />
            </div>
          )}

          {!multipleNeighborhood && (
            <SimpleDropDown
              disabled={disabledSelect || !neighborhoodsOptions.length}
              name="neighborhood"
              placeholder="Selecciona colonia o barrio"
              value={currentNeighborhoodId || ''}
              onChange={({ target }) => {
                const neighborhood = neighborhoodsOptions.find(
                  so => so.value === target.value,
                )
                const state = stateOptions.find(
                  so => so.value === neighborhood.stateId,
                )
                const municipality = municipalitiesOptions.find(
                  so => so.value === neighborhood.municipalityId,
                )
                this.props.onLocationsChange([
                  `${neighborhood.postalCode}::${neighborhood.name}::${municipality.name}::${state.name}`,
                ])

                this.setState({
                  currentStateId: state.id,
                  currentMunicipalityId: municipality.id,
                })
                this.setState({
                  currentNeighborhoodId: target.value,
                })

                const { label, value, ...neighborhoodData } = neighborhood
                this.props.actions.updateLocations(neighborhoodData)
              }}
            >
              {neighborhoodsOptions.map(({ value, label }, i) => (
                <option value={value} key={i}>
                  {label}
                </option>
              ))}
            </SimpleDropDown>
          )}
        </div>
        <style jsx>{locationStyle}</style>
        <style jsx>{style}</style>
      </SectionControlFilter>
    )
  }
}

const mapToOptions = e => ({
  value: e.id,
  label: e.name,
  ...e,
})

const mapNeighborhoodsToOptions = e => ({
  value: e.id,
  label: `${e.name} - ${e.postalCode}`,
  ...e,
})

const sortOptions = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

const mapStateToProps = (state, props) => {
  const states = store.states('locationService').selector(state).response || []
  const municipalities =
    store.municipalities('locationService').selector(state).response || []
  const neighborhoods =
    store.neighborhoods('locationService').selector(state).response || []
  return {
    filters: props.filters ? props.filters : state.bin.filters,
    stateOptions: states.map(mapToOptions).sort(sortOptions),
    municipalitiesOptions: municipalities.map(mapToOptions).sort(sortOptions),
    neighborhoodsOptions: neighborhoods
      .map(mapNeighborhoodsToOptions)
      .sort(sortOptions),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getStates: store.states('locationService').action,
        getMunicipalities: store.municipalities('locationService').action,
        getNeighborhoods: store.neighborhoods('locationService').action,
        clearMunicipalities: store.municipalities('locationService').clear,
        clearNeighborhoods: store.neighborhoods('locationService').clear,
        updateLocations: BinActionCreators.updateLocations,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BinFilterLocation)
