import { PAGES, URL_TYPE } from 'pagesConfig/utils/constants'
import { getSeoUrlsByPage } from 'pagesConfig/utils/seoUrls'
import * as SRP from 'pagesConfig/utils/srp'

export {
  PAGES,
  URL_TYPE,
  getSeoUrlsByPage,
  SRP,
}
