import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { utils } from 'services'
import { capitalize } from 'services/string'
import { OPERATION_TYPES, PROPERTY_TYPES } from 'app/services/seo'
import { ACTIVE_PLANS_TYPES, SALE_TYPES, DEVELOPMENT_TYPES, IS_A_DEVELOPMENT_OPTIONS } from './config'

import { LabelWithRemove } from 'controls/Label'
import FeatureFlag from 'components/FeatureFlag'

import AmenitiesFilters from 'components/PropertyFilters/AmenitiesFilters'

import { filterList } from './styles'

class FilterList extends Component {
  static propTypes = {
    filters: PropTypes.shape({
      propertyType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]),
      operationType: PropTypes.string,
      locations: PropTypes.array,
      age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bedrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bathrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      halfBathrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      parkingSpaces: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      minPrice: PropTypes.string,
      maxPrice: PropTypes.string,
      selectedLocations: PropTypes.array,
      activePlan: PropTypes.any,
      saleType: PropTypes.string,
      isADevelopment: PropTypes.string,
      developmentType: PropTypes.string,
    }).isRequired,
    blockedFilters: PropTypes.array.isRequired,
    onRemoveFilter: PropTypes.func.isRequired
  }

  static defaultProps = {
    blockedFilters: []
  }

  handleRemoveFilter = (filter, value = '') => () => {
    const { filters = {}, onRemoveFilter } = this.props
    if (filters[filter]) {
      onRemoveFilter({
        ...filters,
        [filter]: value
      })
    }
  }

  handleRemoveNexId = removedId => {
    const { filters = {}, onRemoveFilter } = this.props

    const { filtersId = [], ...restFilters } = filters

    onRemoveFilter({
      ...restFilters,
      id: filtersId.filter(id => id !== removedId)
    })
  }

  getPluralText = (number, max, thingPlural, thingSingular) =>
    `${number} ${number === '1' ? thingSingular : thingPlural} ${
      number === max ? ' o más' : ''
    }`

  render() {
    const {
      filters = {},
      blockedFilters,
      customContainerClass,
      customComponentsClass
    } = this.props
    const {
      filtersId = [],
      propertyType,
      operationType,
      bedrooms,
      bathrooms,
      halfBathrooms,
      parkingSpaces,
      minPrice,
      maxPrice,
      selectedLocations,
      search,
      saleType,
      isADevelopment,
      developmentType,
    } = filters

    const activePlan= Array.isArray(filters.activePlan) ? "OTHER" : filters.activePlan
    const propertyTypeArray =
      (propertyType && Array.isArray(propertyType) && propertyType) ||
      (propertyType && [propertyType]) ||
      []

    return (
      <div className={classnames('header-filter', customContainerClass)}>
        {!utils.isEmpty(filters) && (
          <div className="filters-active">
            <h4 className="title-filters">Filtros</h4>
            {filtersId.length
              ? filtersId.map((id, index) => (
                  <LabelWithRemove
                    key={index}
                    color="warning"
                    size="small"
                    onRemove={() => this.handleRemoveNexId(id)}
                    style={{ margin: '.2rem' }}
                    className={classnames(customComponentsClass)}
                  >
                    {`NEX-${id}`}
                  </LabelWithRemove>
                ))
              : null}
            {search && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('search')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {search}
              </LabelWithRemove>
            )}
            {propertyTypeArray &&
              propertyTypeArray.map((pt, i) => (
                <LabelWithRemove
                  key={i}
                  size="small"
                  onRemove={this.handleRemoveFilter('propertyType')}
                  style={{ margin: '.2rem' }}
                  className={classnames(customComponentsClass)}
                >
                  {(PROPERTY_TYPES.find(ot => ot.value === pt) || {}).label}
                </LabelWithRemove>
              ))}
            {operationType && (
              <LabelWithRemove
                size="small"
                disabled={blockedFilters.includes('operationType')}
                onRemove={this.handleRemoveFilter('operationType')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {
                  (OPERATION_TYPES.find(ot => ot.value === operationType) || {})
                    .label
                }
              </LabelWithRemove>
            )}
            {bedrooms && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('bedrooms')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {this.getPluralText(bedrooms, '5', 'recámaras', 'recámara')}
              </LabelWithRemove>
            )}
            {bathrooms && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('bathrooms')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {this.getPluralText(bathrooms, '5', 'baños', 'baño')}
              </LabelWithRemove>
            )}
            {halfBathrooms && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('halfBathrooms')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {this.getPluralText(
                  halfBathrooms,
                  '5',
                  'medios baños',
                  'medio baño'
                )}
              </LabelWithRemove>
            )}
            {parkingSpaces && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('parkingSpaces')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {this.getPluralText(
                  parkingSpaces,
                  '5',
                  'estacionamientos',
                  'estacionamiento'
                )}
              </LabelWithRemove>
            )}
            {minPrice && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('minPrice')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {`mínimo: ${minPrice}`}
              </LabelWithRemove>
            )}
            {maxPrice && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('maxPrice')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {`máximo: ${maxPrice}`}
              </LabelWithRemove>
            )}
            {selectedLocations &&
              selectedLocations.map(sl => (
                <LabelWithRemove
                  size="small"
                  onRemove={this.handleRemoveFilter(
                    'selectedLocations',
                    selectedLocations.filter(ssl => ssl !== sl)
                  )}
                  style={{ margin: '.2rem' }}
                  className={classnames(customComponentsClass)}
                  key={sl}
                >
                  {sl
                    .split('::')
                    .filter(s => s !== '')
                    .map(loc =>
                      loc
                        .split('-')
                        .map(capitalize)
                        .join(' ')
                    )
                    .join(', ')}
                </LabelWithRemove>
              ))}
            {activePlan && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('activePlan')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {
                  (ACTIVE_PLANS_TYPES.find(ot => ot.value === activePlan) || {})
                    .label
                }
              </LabelWithRemove>
            )}
            {saleType && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('saleType')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {(SALE_TYPES.find(ot => ot.value === saleType) || {}).label}
              </LabelWithRemove>
            )}
            {isADevelopment && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('isADevelopment')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {(IS_A_DEVELOPMENT_OPTIONS.find(ot => ot.value === isADevelopment) || {}).label}
              </LabelWithRemove>
            )}
            {developmentType && (
              <LabelWithRemove
                size="small"
                onRemove={this.handleRemoveFilter('developmentType')}
                style={{ margin: '.2rem' }}
                className={classnames(customComponentsClass)}
              >
                {(DEVELOPMENT_TYPES.find(ot => ot.value === developmentType) || {}).label}
              </LabelWithRemove>
            )}

            <FeatureFlag name="seo-amenities">
              <AmenitiesFilters
                filters={filters}
                className={classnames(customComponentsClass)}
                handleRemoveFilter={this.handleRemoveFilter}
              />
            </FeatureFlag>
          </div>
        )}
        <style jsx>{filterList}</style>
      </div>
    )
  }
}

export default FilterList
