import colors from 'theme/colors'
import { camelToHyphen } from 'services/string'
import fonts, { fontSizes, fontSizesMobile } from 'theme/fonts'
import { measurements } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .layoutContainer {
    background-color: ${colors.primaryColor};
  }
`

export const nProgressStyle = css.global`
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${colors.secondary};

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 0.35rem;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: none;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
   .configuration-mode{
    display: flex;
    background: ${colors.lightGray};
    width: 100%;
    justify-content: center;
    top: 0;
    z-index: 1000;
    left: 0;
  }
  .configuration-mode p{
    margin:.5rem;
  }
`

export const global = css.global`
  .cc_overlay_lock{
    background: ${colors.text};
  }
  .cc_css_reboot{
    border-radius: .5rem;
  }
  h1.cc_dialog_headline{
    color: ${colors.text};
  }
  p.cc_dialog_text{
    color: ${colors.text};
  }
  button.cc_b_ok, .cc_cp_f_save button{
    background: ${colors.primary};
    color: ${colors.white};
    border-radius: .5rem;
  }
  .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer .cc_cp_f_powered_by{
    display: none!important;
  }
  .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer{
    padding: 1rem;
  }
  .cc_cp_container, .cc_cp_head{
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }
  .cc_cp_footer, .cc_cp_container{
     border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }
  #neximo-root {
    margin: 0;
    padding: 0;
    font: ${fonts.regular};
    color: ${colors.text};
    font-size: ${fontSizes.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: static;
    overflow: visible;
  }

  #neximo-root * {
    box-sizing: border-box;
  }

  .app-wrapper-container {
    max-width: ${measurements.containerSize};
    margin-right: auto;
    margin-left: auto;
  }

  .limited-width {
    max-width: 1110px;
    width: 100%;
  }
  .container-body{
    background: ${colors.white};
    padding: 2rem;
    border: solid 1px ${colors.border};
    width:100%;
  }

  a {
    text-decoration: none;
    color: ${colors.white};
  }

  p {
    font-size: ${fontSizes.p};
  }

  h1 {
    font: ${fonts.regular};
    font-size: ${fontSizes.h1};
    color: ${colors.text};
  }

  h2 {
    font: ${fonts.regular};
    font-size: ${fontSizes.h2};
    color: ${colors.text};
  }
  h3 {
    font: ${fonts.regular};
    font-size: ${fontSizes.h3};
    color: ${colors.text};
  }

  h4 {
    font: ${fonts.regular};
    font-size: ${fontSizes.h4};
    color: ${colors.text};
  }

  h5 {
    font: ${fonts.regular};
    font-size: ${fontSizes.h5};
    color: ${colors.text};
  }
  @media screen and (max-width: 992px) {
    .container-body{
      margin:0;
      padding:.5rem;
    }
    .hideOnMobile {
      display: none;
    }
    p {
      font-size: ${fontSizesMobile.p};
    }

    h1 {
      font: ${fonts.regular};
      font-size: ${fontSizesMobile.h1};
      color: ${colors.text};
    }

    h2 {
      font: ${fonts.regular};
      font-size: ${fontSizesMobile.h2};
      color: ${colors.text};
    }

    h3 {
      font: ${fonts.regular};
      font-size: ${fontSizesMobile.h3};
      color: ${colors.text};
    }

    h4 {
      font: ${fonts.regular};
      font-size: ${fontSizesMobile.h4};
      color: ${colors.text};
    }

    h5 {
      font: ${fonts.regular};
      font-size: ${fontSizesMobile.h5};
      color: ${colors.text};
    }
  }
  @media screen and (min-width: 992px) {
    .hideOnLarge {
      display: none;
    }
  }

  .wp-container {
    width: 90%;
    border: solid 1px ${colors.border};
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 3.12rem;
    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: 1200px) {
    .app-wrapper-container {
      max-width: ${measurements.containerLargeSize};
    }
  }

`
export const alignStyle = css.global`
.container-body.align-center{

  margin: 0 auto;
  margin-top: 1rem;
}

.container-body.container-second{

  margin-top: 1rem;
}

.text-align-left{

  text-align: left;
}

.text-align-center{

  text-align: center;
}

.text-align-right{

  text-align: right;
}

.text-vertical-align-bottom{

  align-self: flex-end;
}
`

export const fancyTableStyle = css.global`

.table-detail .box{

  padding: 5px;
}

.table-detail .header{

  border-bottom: solid 3px ${colors.text};
}

.table-detail .header .box{

  font-weight: 600;
}

.table-detail .row{

  border-bottom: solid 1px ${colors.border};
}

.table-detail .row:nth-child(even){

  background-color: ${colors.lightGray};
}
`

export const loadingStyle = css.global`

  .loading-content{

    text-align: center;
    padding-top:1rem;
  }

  .loading-overlay{
    display: inline-block;
  }
`

export const containerContent = css.global`

.container-content {
  background: ${colors.white};
  padding: 2rem;
  border: solid 1px ${colors.border};
  max-width: 70rem;
  width: 100%;
  margin-bottom:2rem;
}
`

export const forms = css.global`
  div.form-input-margin {
    margin-top: 1rem;
  }
`

export const container = css`
  .container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`


export const text = css.global`
  .primary-text {
    color: ${colors['primary']};
  }
  .light-primary-text {
    color: ${colors['lightPrimary']};
  }
  .secondary-text {
    color: ${colors['secondary']};
  }
  .text-text {
    color: ${colors['text']};
  }
  .text-blue-background-text {
    color: ${colors['textBlueBackground']};
  }
  .text-blue-bright-text {
    color: ${colors['textBlueBright']};
  }
  .light-text-text {
    color: ${colors['lightText']};
  }
  .alt-text-text {
    color: ${colors['altText']};
  }
  .badge-text {
    color: ${colors['badge']};
  }
  .badge-yellow-text {
    color: ${colors['badgeYellow']};
  }
  .border-text {
    color: ${colors['border']};
  }
  .border-input-text {
    color: ${colors['borderInput']};
  }
  .light-border-text {
    color: ${colors['lightBorder']};
  }
  .purple-text {
    color: ${colors['purple']};
  }
  .frame-text {
    color: ${colors['frame']};
  }
  .terciary-text {
    color: ${colors['terciary']};
  }
  .white-text {
    color: ${colors['white']};
  }
  .black-text {
    color: ${colors['black']};
  }
  .gray-text {
    color: ${colors['gray']};
  }
  .light-gray-text {
    color: ${colors['lightGray']};
  }
  .lighter-gray-text {
    color: ${colors['lighterGray']};
  }
  .dark-gray-text {
    color: ${colors['darkGray']};
  }
  .dull-gray-text {
    color: ${colors['dullGray']};
  }
  .gray-background-text {
    color: ${colors['grayBackground']};
  }
  .disabled-text {
    color: ${colors['disabled']};
  }
  .danger-text {
    color: ${colors['danger']};
  }
  .success-text {
    color: ${colors['success']};
  }
  .warning-text {
    color: ${colors['warning']};
  }
  .light-warning-text {
    color: ${colors['lightWarning']};
  }
  .transparent-text {
    color: ${colors['transparent']};
  }
  .sell-text {
    color: ${colors['sell']};
  }
  .rent-text {
    color: ${colors['rent']};
  }
  .dark-green-text {
    color: ${colors['darkGreen']};
  }
  .box-shadow-text {
    color: ${colors['boxShadow']};
  }
  .highlight-blue-text {
    color: ${colors['highlightBlue']};
  }
  .info-text {
    color: ${colors['info']};
  }
  .caution-orange-text {
    color: ${colors['cautionOrange']};
  }
  .tertiary-text {
    color: ${colors['tertiary']};
  }
  .hero-bg-text {
    color: ${colors['heroBg']};
  }
  .outline-frame-text {
    color: ${colors['outlineFrame']};
  }
  .footer-bg-text {
    color: ${colors['footerBg']};
  }
  .light-bg-text {
    color: ${colors['lightBg']};
  }
  .facebook-text {
    color: ${colors['facebook']};
  }
  .dark-warning-text {
    color: ${colors['darkWarning']};
  }
  .danger-low-text {
    color: ${colors['dangerLow']};
  }
  .warning-low-text {
    color: ${colors['warningLow']};
  }
  .dark-blue-text {
    color: ${colors['darkBlue']};
  }
  .indigo-text {
    color: ${colors['indigo']};
  }
  .capri-blue-text {
    color: ${colors['capriBlue']};
  }
  .baby-blue-text {
    color: ${colors['babyBlue']};
  }
  .celestial-blue-text {
    color: ${colors['celestialBlue']};
  }
  .light-secondary-text {
    color: ${colors['lightSecondary']};
  }
  .dark-secondary-text {
    color: ${colors['darkSecondary']};
  }
  .dark-primary-text {
    color: ${colors['darkPrimary']};
  }
  .dark-frame-text {
    color: ${colors['darkFrame']};
  }
  .dark-text-text {
    color: ${colors['darkText']};
  }
  .line-through {
    text-decoration: line-through;
  }
`


export const layout = css`
  .main-layout-container-full-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .main-layout-container-full-height :global(> *){
    width: 100%;
  }
`