import React from 'react'
import classnames from 'classnames'

import StatesFooterLinks from 'components/Footer/NavigationPropertyFooter/StatesFooterLinks'
import AmenitiesLinks from 'components/Footer/NavigationPropertyFooter/AmenityLinks'
import { PropertyPublicPaginate } from 'components/Paginate'
import { Button, IonIcon } from 'controls'
import { PropertyCarousel } from 'components/Carousel'
import SRPCard from 'components/SRPCard'
import FeatureFlag from 'components/FeatureFlag'
import SRPFilters from 'components/Srp/SRPFilters'

import Results from './Results'
import styles from './styles'

const SrpContainer = ({
  filtersOn,
  isIndexPage,
  isSearchPage,
  showPagination,
  highlightsProperties,
  handleCloseFilters,
  applyFilters,
  handleFilterChange,
  updateFilters,
  filters,
  count,
  results,
  loadingData,
  pageCount
}) => (
  <div
    className={classnames('wrapper-container', 'srp-container', {
      active: filtersOn
    })}
  >
    <section className="container app-wrapper-container">
      {isIndexPage && <h2>Últimas propiedades</h2>}

      <div className={classnames('results', { 'is-index-page': isIndexPage })}>
        {showPagination && (
          <div
            className={classnames('wrapper-bin-filters', {
              active: filtersOn
            })}
          >
            <div
              className={classnames('actions-filters', {
                active: filtersOn
              })}
            >
              <Button
                color="primary"
                className="close-filters-button"
                onClick={handleCloseFilters}
              >
                <IonIcon icon="md-close" />
              </Button>
            </div>

            <SRPFilters
              showAmenitiesFilter
              className={classnames({
                active: filtersOn
              })}
              applyFilters={applyFilters}
              handleFilterChange={handleFilterChange}
              updateFilters={updateFilters}
              filters={filters}
              multipleNeighborhood={false}
            />
          </div>
        )}
        <div className="container-results-grid">
          <Results
            count={count}
            results={results}
            loadingData={loadingData}
            filters={filters}
            applyFilters={applyFilters}
            showBreadcrumb={!isIndexPage}
            isIndexPage={isIndexPage}
          />

          {showPagination && !isSearchPage && (
            <div className="paginate">
              <PropertyPublicPaginate
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
              />
            </div>
          )}
        </div>
      </div>
    </section>

    <FeatureFlag name="more-properties-suggested" evaluateIf={isIndexPage}>
      <section className="container properties-suggested-container app-wrapper-container">
        {highlightsProperties.map(({ title, properties }, carouselIndex) => (
          <PropertyCarousel title={title} key={carouselIndex}>
            {properties.map((property, index) => (
              <SRPCard
                isSmall
                key={index}
                propertyData={property}
                className="srp-results-highlights-properties"
              />
            ))}
          </PropertyCarousel>
        ))}
      </section>
    </FeatureFlag>

    <FeatureFlag
      name="suggested-links"
      defaultChildren={
        <section className="container suggested-links app-wrapper-container">
          <StatesFooterLinks isIndexPage={isIndexPage} levelsToShow={isIndexPage ? 1 : 3} />
        </section>
      }
    />

    {!isIndexPage && <AmenitiesLinks />}

    <style jsx>{styles}</style>
  </div>
)

export default SrpContainer
