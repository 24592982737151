import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SrpActionCreators } from 'app/reducers/SrpRedux'
import Router from 'next/router'

import { removeUnnusedSRPParams, srpUrl } from 'services/seo'
import { normalizeAsUrl } from 'services/string'

import HeaderSearch from 'components/Srp/HeaderSearch'
import JsonLD from 'components/SEO/JsonLD'
import store from 'services/store'
import { removeEmptyKeys } from 'services/utils'
import { BASE_SRP_URL } from 'pagesConfig/utils/constants'

import { SEARCH_CONFIG } from 'components/Srp'

import SrpContainer from './SrpContainer'
import { getJsonLDListItemProperties } from 'components/SEO/data'
import { getValidAmenityParams } from 'services/seo/amenities'

class SearchResults extends Component {
  static propTypes = {
    searchRequestData: PropTypes.object,
    showPagination: PropTypes.bool,
    isIndexPage: PropTypes.bool
  }

  static defaultProps = {
    showPagination: true,
    isIndexPage: false
  }

  constructor(props) {
    super(props)
    const { offset } = this.props.filters
    this.state = {
      selected: offset ? offset / SEARCH_CONFIG.limit : 0,
      filtersOn: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.filters.offset !== nextProps.filters.offset) {
      this.setState({
        selected: nextProps.filters.offset
          ? nextProps.filters.offset / SEARCH_CONFIG.limit
          : 0
      })
    }
  }

  handleApplyFilters = (page = 0) => filters => {
    const {
      scrollToTop = true,
      property_type,
      operation_type,
      selectedLocations,
      ...restFilters
    } = filters

    let params = {
      propertyId: '',
      limit: 10,
      offset: 0,
      bedrooms: '',
      bathrooms: '',
      parkingSpaces: '',
      propertyType: '',
      operationType: '',
      minPrice: '',
      maxPrice: '',
      selectedLocations: '',
      ...getValidAmenityParams(filters)
    }

    params['operationType'] = filters.operationType || 'SELL'

    if (filters.id) {
      params['propertyId'] = validators.getNumber(filters.id)
    }

    if (filters.bedrooms === '5') {
      params['minBedrooms'] = filters.bedrooms
    } else {
      params['bedrooms'] = filters.bedrooms
    }

    if (filters.bathrooms === '5') {
      params['minBathrooms'] = filters.bathrooms
    } else {
      params['bathrooms'] = filters.bathrooms
    }

    if (filters.parkingSpaces === '5') {
      params['minParkingSpaces'] = filters.parkingSpaces
    } else {
      params['parkingSpaces'] = filters.parkingSpaces
    }

    if (filters.propertyType) {
      params['propertyType'] = filters.propertyType
    }
    if (filters.minPrice) {
      params['minPrice'] = filters.minPrice
    }
    if (filters.maxPrice) {
      params['maxPrice'] = filters.maxPrice
    }
    if (filters.search) {
      params['search'] = filters.search
    }

    if (filters.selectedLocations && filters.selectedLocations.length > 0) {
      let loc = filters.selectedLocations

      const rep = loc => {
        let result = loc.split('::').filter(v => v)

        if (result.length === 4) {
          result = result.slice(1)
        }

        result = result.join('--').replace(/ /gi, '-')

        return normalizeAsUrl(result)
      }

      if (Array.isArray(loc)) {
        loc = rep(loc[0])
      } else {
        loc = rep(loc)
      }

      params.location = loc
    }

    params = removeEmptyKeys(params)

    // Remove filters that you don't want to show at URL as param
    // Example: operationType="RENT" becomes in '/en-renta' and not in '/?operationType=RENT'

    const finalFilters = removeUnnusedSRPParams(restFilters)

    Router.push(
      {
        pathname: '/srp',
        query: params
      },
      {
        pathname:
          srpUrl(params) === '/'
            ? BASE_SRP_URL
            : `${srpUrl(params)}`,
        query: removeEmptyKeys(finalFilters)
      },
      {
        scroll: scrollToTop
      }
    )
  }

  render() {
    const {
      pageLoading,
      isIndexPage,
      isSearchPage,
      showPagination,
      showHeaderSearch = false,
      highlightsProperties,
      footerLinks
    } = this.props
    const { selected } = this.state
    const { count, results } = this.props.searchRequestData.response || {
      count: 0,
      results: []
    }
    const pageCount = Math.ceil(count / SEARCH_CONFIG.limit)
    const jsonLDItemList = getJsonLDListItemProperties(results)

    return (
      <Fragment>
        <JsonLD data={jsonLDItemList} />

        {showHeaderSearch && (
          <HeaderSearch
            applyFilters={this.handleApplyFilters(0)}
            updateFilters={this.props.actions.updateFilters}
            filters={this.props.filters}
            handleShowOptions={() => this.setState({ filtersOn: true })}
          />
        )}

        <SrpContainer
          count={count}
          results={results}
          selected={selected}
          pageCount={pageCount}
          loadingData={pageLoading}
          filters={this.props.filters}
          showPagination={showPagination}
          filtersOn={this.state.filtersOn}
          isIndexPage={isIndexPage}
          isSearchPage={isSearchPage}
          highlightsProperties={highlightsProperties}
          footerLinks={footerLinks}
          applyFilters={this.handleApplyFilters(0)}
          updateFilters={this.props.actions.updateFilters}
          handleShowFilters={() => this.setState({ filtersOn: true })}
          handleFilterChange={() => this.setState({ filtersOn: false })}
          handleCloseFilters={() => this.setState({ filtersOn: false })}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  searchRequestData: store.publicSearch('property').selector(state),
  currentLocation: state.geo.currentLocation,
  filters: state.srp.filters,
  highlightsProperties: state.srp.highlightsProperties,
  footerLinks: state.srp.footerLinks
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        updateFilters: SrpActionCreators.updateFilters
      },
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults)
