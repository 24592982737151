const getNavBarProps = (props, state) => {
  const {
    whiteNavBar,
    blueNavBar,
    isAuthenticated,
    hiddeValuationBtn
  } = props

  const { burgerMenuOpen, fixed } = state

  const negativeMenu = whiteNavBar || props.negativeMenu || state.negativeMenu

  const baseNavBarProps = {
    blueNavBar,

    isAuthenticated,
    hiddeValuationBtn,
    negativeMenu,

    whiteNavBar,
    negativeMenuProps: props.negativeMenu,
    negativeMenuState: state.negativeMenu
  }

  const desktopNavBarProps = {
    ...baseNavBarProps,
    fixed
  }
  
  const mobileNavBarProps = {
    ...baseNavBarProps,
    burgerMenuOpen
  }

  return { desktopNavBarProps, mobileNavBarProps }
}

export default getNavBarProps
