import { colors, measurements, fontSizes } from 'app/theme'
import css from 'styled-jsx/css'

export const headerSearchStyles = css`
  .header-search-wrapper {
    border-bottom: 1px solid ${colors.border};
    margin: 5.625rem auto 0;
  }
  .header-search-content {
    max-width: ${measurements.containerSize};
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-search-content .header-search-breadcrumb {
    padding: 0.5rem;
  }
  .header-search-content .header-search-input {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }
  .header-search-content .header-search-input :global(.input input) {
    width: 17rem;
    border: 1px solid ${colors.border};
    border-radius: 0.2rem 0 0 0.2rem;
  }
  .header-search-content .header-search-input :global(.header-search-button) {
    width: 4rem;
    height: 2.2rem;
    border: 1px solid ${colors.border};
    border-left: none;
    border-radius: 0 0.2rem 0.2rem 0;
  }
  .header-search-content .header-search-input :global(.show-options-button) {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .header-search-wrapper,
    .header-search-content {
      background-color: ${colors.white};
      position: sticky;
      top: 3.75rem;
      left: 0;
      right: 0;
      z-index: 5;
    }
    .header-search-content {
      flex-wrap: wrap;
    }

    .header-search-content .header-search-input :global(.show-options-button) {
      display: initial;
      margin-right: 0.5rem;
    }

    .header-search-content .header-search-input,
    .header-search-content .header-search-input :global(.input),
    .header-search-content .header-search-input :global(.input input) {
      width: 100%;
    }
    .header-search-wrapper {
      margin-top: 4.75rem;
    }
  }
`
export const breadcrumbStyles = css`
  .srp-breadcrumb-wrapper {
    display: inline;
  }
  .srp-breadcrumb-wrapper ol {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-size: ${fontSizes.p};
    padding: 0;
    margin: 0;
  }

  .srp-breadcrumb-wrapper .breadcrumb-link-to-go-back {
    padding: 0.1rem 0.4rem;
    border-radius: 0.3rem;
    margin-right: 0.2rem;
    transition: all 0.3s;
    color: ${colors.white};
    background-color: ${colors.secondary};
  }
  .srp-breadcrumb-wrapper .breadcrumb-element-separator {
    color: ${colors.gray};
  }
  .srp-breadcrumb-wrapper a {
    color: ${colors.secondary};
  }
  .srp-breadcrumb-wrapper a:hover {
    text-decoration: underline;
  }
  .srp-breadcrumb-wrapper a.breadcrumb-link-to-go-back:hover {
    text-decoration: none;
    box-shadow: 0 0 0px 3px ${colors.border};
  }

  .srp-breadcrumb-wrapper ol li {
    margin-right: 0.2rem;
    font-size: .8rem;
  }
  .srp-breadcrumb-wrapper ol li:after {
    content: '›';
    float: none;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    margin-left: 0.2rem;
  }
  .srp-breadcrumb-wrapper ol li:last-child:after {
    content: '';

  }
  .srp-breadcrumb-wrapper ol li:last-child .link {
    cursor: not-allowed;
    color: ${colors.gray};
    text-decoration: none;
  }
`