import React, { Fragment } from 'react'

import { slide as BurgerMenu } from 'react-burger-menu'

import { IonIcon, Button } from 'controls'

import { NavBarHamburgerContainer } from './NavigationContainers'

import {
  IndexLink,
  LinkLogoHome,
  LinkJoin,
  LinkSrp,
  LinkTeam,
  MagazineLink,
  AuthDashboardLink,
  AuthLink,
  ValuationLink,
  CountryLink
} from './NavigationLinks'

import { navBarMobile } from './styles'

const NavBarMobileMenu = ({
  whiteNavBar,
  negativeMenuProps,
  negativeMenuState,

  blueNavBar,

  burgerMenuOpen,
  handleCloseMenu,
  isAuthenticated,
  hiddeValuationBtn,
  negativeMenu
}) => (
  <NavBarHamburgerContainer
    whiteNavBar={whiteNavBar}
    negativeMenuProps={negativeMenuProps}
    negativeMenuState={negativeMenuState}
    blueNavBar={blueNavBar}
  >
    <BurgerMenu
      right
      width={'200px'}
      isOpen={burgerMenuOpen}
      customBurgerIcon={false}
      customCrossIcon={
        <CloseMenuButton
          handleClose={() => handleCloseMenu({ isOpen: false })}
        />
      }
      onStateChange={handleCloseMenu}
    >
      <IndexLink />
      <LinkJoin />
      <LinkSrp />
      <LinkTeam />
      <MagazineLink />
      <AuthDashboardLink isAuthenticated={isAuthenticated} />
      <AuthLink isAuthenticated={isAuthenticated} />
      <ValuationLink hiddeValuationBtn={hiddeValuationBtn} />
      <CountryLink />
    </BurgerMenu>

    <LinkLogoHome negativeMenu={negativeMenu} />
    <OpenMenuButton handleClick={() => handleCloseMenu({ isOpen: true })} />
  </NavBarHamburgerContainer>
)

export const CloseMenuButton = ({ handleClose }) => (
  <Fragment>
    <IonIcon icon="md-close" className="hideOnLarge" onClick={handleClose} />
    <style jsx>{navBarMobile}</style>
  </Fragment>
)

export const OpenMenuButton = ({ handleClick, className }) => (
  <Fragment>
    <Button className={className} onClick={handleClick} style={{ zIndex: '1' }}>
      <IonIcon icon="ios-menu" className="large" />
    </Button>
    <style jsx>{navBarMobile}</style>
  </Fragment>
)

export default NavBarMobileMenu
