import css from 'styled-jsx/css'
import { colors, fontSizes, measurements } from 'theme'

export const footer = css`
  .footer-container {
    color: ${colors.white};
    background-color: ${colors.footerBg};
    padding: 2.875rem .375rem;
    width: 100%;
  }
  .footer {
    margin: 0 auto;
    align-items: center;

    display: flex;
    justify-content: space-between;
  }
  
  .footer-copyright, .footer-terms {
    padding: 0.5rem 0;

    font-size: ${fontSizes.body};
  }

  .terms-section,
  .help-section {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 40% 20% 40%;
    align-items: center;
  }

  .footer-logo {
    display: flex;
  }

  img.logo-img {
    max-width: 12rem;
    margin: 0 auto;
    width: 100%;
  }

  .social-media-links {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
  }

  @media screen and (max-width: 992px) {
    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    .footer-section {
      margin: 1.5rem 0;
    }
  }
`
