import css from 'styled-jsx/css'
import { colors, measurements, fontWeights, fontSizes } from 'theme'

export const footerFiltersStyles = css`
  .footer-filters-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .footer-filters-container :global(.operation-types-filters),
  .footer-filters-container :global(.property-types-filters) {
    padding: 0;
    list-style: none;
  }

  @media screen and (max-width: 992px) {
    .footer-filters-container {
      margin-top: 2rem;
    }
  }
`

export const filtersPanelStyles = css`
  .filters-panel {
    border-bottom: 2px solid ${colors.border};
    display: flex;
  }

  .filters-panel :global(.label.footer-filter-label) {
    margin: 0.3rem;
    user-select: none;
  }
  .filters-panel :global(.label.footer-filter-label a) {
    color: ${colors.text};
  }
  .filters-panel :global(.label.primary.footer-filter-label a) {
    color: ${colors.white};
  }

  @media screen and (max-width: 992px) {
    .filters-panel :global(.label.footer-filter-label) {
      margin: 0.6rem;
    }
  }
`

export const operationTypesFiltersStyles = css`
  .operation-types-filters {
    display: flex;
  }
  .operation-types-filters :global(.under-hightligh-button) {
    border-radius: 0;
    padding: 1.1rem 1.5rem;
  }

  .operation-types-filters :global(.under-hightligh-button .anchor) {
    color: ${colors.text};
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.p};
    user-select: none;
  }

  .operation-types-filters :global(.under-hightligh-button.is-active) {
    box-shadow: 0px 4px 0px -2px ${colors.primary};
  }
  .operation-types-filters :global(.under-hightligh-button.is-active .anchor) {
    color: ${colors.primary};
  }
`
export const propertyTypesFiltersStyles = css`
  .property-types-filters {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 768px) {
    .property-types-filters {
      flex-direction: column;
    }
  }
`