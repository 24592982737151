import { colors, fonts, fontSizes, measurements } from 'theme'
import css from 'styled-jsx/css'

export const countryMenu = css`
  .subnav {
    position: relative;
    padding: 1rem;
  }
  .subnav .subnavbtn {
    border: none;
    outline: none;
    color: inherit;
    background-color: inherit;
    margin: 1rem 0;
    border-left: 1px solid;
    border-right: 1px solid;
    cursor: pointer;
  }
  .subnav-content {
    display: none;
    position: absolute;
    right: 10px;
    background-color: ${colors.white};
    width: 150%;
    z-index: 1;
    text-align: center;
    border-radius: ${measurements.borderRadius};
  }
  
  .subnav-content a {
    display: block;
    color: ${colors.text};
    text-decoration: none;
    padding: 1rem;
  }

  .subnav-content a:hover {
    background-color: ${colors.border};
    border-radius: ${measurements.borderRadius};
  }
  .subnavbtn:focus + .subnav-content,
  .subnav:hover .subnav-content{
    display:block;
  }

  @media screen and (max-width: 992px) {
    .subnav-content {
      right: 0;
      width: 100%;
    }
    .subnav-content a {
      margin: 0;
    }
  }
`

export const contactHeader = css`
  .contact-header-container {
    width: 100%;
    height: 1.874rem;
    background-color: ${colors.secondary};
  }
`

export const navBar = css`
  .nav-bar-container {
    width: 100%;
    position: relative;
    top: 0;
    flex-direction: row;
    align-items: center;
    background-color: ${colors.transparent};
    z-index: 100;

    transition: 120ms background-color;
  }

  .nav-bar-container.negative {
    background-color: ${colors.white};
    transition: 120ms background-color;
  }

  .nav-bar-container.blueNavBar {
    background-color: ${colors.text}
  }

  .nav-bar-container.over-fixed {
    position: absolute;
  }
  .nav-bar-container.fixed {
    position: fixed;
  }

  .nav-bar-actions {
    height: 5.625rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: auto;
    position: relative;

    transition: 120ms height;
  }

  .negative .nav-bar-actions {
    height: 4.375rem;

    transition: 120ms height;
  }

  .negative .nav-bar-actions .logo {
    top: 1.25rem;
    transition: 120ms top;
  }

  .logo {
    width: 160px;
    position: absolute;
    top: 1.5rem;
    left: 0;
    opacity: 1;

    transition: 120ms opacity, 120ms top;
  }

  .logo img {
    position: absolute;
    height: 2.5rem;
  }

  .logo .logo-image.hidde-logo {
    display: none;
  }

  .links-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: ${colors.white};

    transition: 120ms color;
  }

  .negative .nav-bar-actions :global(.link) {
    color: ${colors.text};

    transition: 120ms color;
  }

  .nav-bar-container.blueNavBar  .nav-bar-actions :global(.link) {
    color: ${colors.white};
  }

  
  .nav-bar-actions a:last-child {
    padding-right: 0;
  }

  .nav-bar-actions .button.primary {
    height: 2.375rem;
    width: 14rem;
  }
  .negative .nav-bar-actions .button.primary,
  .negative .nav-bar-actions .button.primary * {
    border-color: ${colors.primary};
    color: ${colors.primary};    
    transition: 120ms all;
  }

  .nav-bar-container .nav-bar-actions .authlinks {
    margin-left: 0.5rem;
  }

  .nav-bar-container .nav-bar-actions :global(.handle-show-nav-bar-mobile-menu-button) {
      display: none;
  }

  @media screen and (max-width: 992px) {
    .logo {
      height: 2.5rem;
      position: static;
    }
    .logo img {
      max-height: 2.5rem;
      position: absolute;
      opacity: 1;
      left: 0.5rem;
      transition: opacity 120ms ease 0s;
    }

    .nav-bar-container .nav-bar-actions :global(a),
    .nav-bar-container .nav-bar-actions :global(.link),
    .nav-bar-container .nav-bar-actions :global(.valuation-btn) {
      display: none;
    }
    .nav-bar-container .nav-bar-actions :global(.handle-show-nav-bar-mobile-menu-button),
    .nav-bar-container .nav-bar-actions :global(.logo) {
      display: initial;
      color: white;
    }
  }
`

export const navBarMobile = css`
  .nav-bar-container {
    width: 100%;
    box-sizing: border-box;
    height: 3.75rem;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    background-color: ${colors.transparent};
    color: ${colors.white};
    background-color: ${colors.transparent};
    z-index: 10;

    transition: 120ms background-color;
  }

  .negative {
    background-color: ${colors.white};

    transition: 120ms background-color;
  }

  .nav-bar-container.blueNavBar {
    background-color: ${colors.text};
  }

  .logo {
    height: 40px;
  }

  .logo img {
    max-height: 40px;
    position: absolute;
    opacity: 1;
    left: 0.5rem;

    transition: 120ms opacity;
  }

  .modal-menu {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-menu :global(h2 .link) {
    color: ${colors.text};
  }

  .valuation-btn .button.primary {
    height: initial;
    width: initial;
    border: 1px solid red;
  }
`

export const hamburgerMenu = css.global`
  .nav-bar-container .ionicon {
    cursor: pointer;
  }

  .nav-bar-container.negative .ionicon {
    color: ${colors.text};
    cursor: pointer;
  }
  /* Position and sizing of burger button */
  .nav-bar-container.blueNavBar .ionicon {
    color: ${colors.white};
  }
.bm-burger-button {
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  top: 1rem!important;
}

/* Color/shape of close button cross */
.bm-cross {
  font-size: 1.7rem;
  color: ${colors.white};
}

.bm-overlay {
  top: 0;
  right: 0;
}

.bm-menu-wrap {
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: ${colors.frame};
  padding-top: 2.5em;
  font-size: ${fontSizes.tiny};
}

/* Wrapper for item list */
.bm-item-list {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  align-items: center;
}

.bm-item-list a {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.bm-item-list a i {
  margin-right: 1rem;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

`
