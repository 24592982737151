import { createSelector } from 'reselect'
const paramsUrl = [
  'minPrice',
  'minBathrooms',
  'minBedrooms',
  'minParkingSpaces',
  'search'
]
const queryParams = [
  'bathrooms',
  'bedrooms',
  'parkingSpaces',
  'maxPrice',
  'minPrice',
  'minBathrooms',
  'minBedrooms',
  'minParkingSpaces',
  'search',
  'propertyType',
  'operationType'
]

export const publicFilterPaginate = createSelector(
  state => state.srp.filters,
  filters => {
    let query = { queryParams: {}, urlParams: {} }

    Object.keys(filters).forEach(filter =>
      paramsUrl.includes(filter)
        ? (query.urlParams = {
            ...query.urlParams,
            [filter]: filters[filter]
          })
        : ''
    )
    Object.keys(filters).forEach(filter =>
      queryParams.includes(filter)
        ? (query.queryParams = {
            ...query.queryParams,
            [filter]: filters[filter]
          })
        : ''
    )
    return query
  }
)
