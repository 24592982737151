import React from 'react'
import { image } from 'services'
import { Link, IonIcon } from 'controls'
import config from 'config'
import NextLink from 'next/link'
import SEOLink from 'components/SEO/SEOLink'
import ValuationLinkButton from 'components/Forms/ValuationForm/LinkButton'
import { companyConfig } from 'globalConfigApp'
import { navBar, navBarMobile, countryMenu } from './styles'

const COMPANY_THEME = config('COMPANY_THEME')
const IS_IAD_THEME = COMPANY_THEME !== 'NEXIMO'

const COMPANY_BLOG_URL = config('COMPANY_BLOG_URL')
const JOIN_US_URL = config('JOIN_US_URL')

const LOGOS_BY_COMPANY_THEME = {
  IAD: {
    BLUE: 'iad-logo-blue-3x.png',
    WHITE: 'iad-white-logo.svg',
  },
  NEXIMO: {
    BLUE: 'home/neximo-iad-logo-transición-blue-h.png',
    WHITE: 'home/neximo-iad-logo-transición-white-h.png',
  },
}

const LOGOS = IS_IAD_THEME
  ? LOGOS_BY_COMPANY_THEME.IAD
  : LOGOS_BY_COMPANY_THEME.NEXIMO

export const LinkLogoHome = ({ negativeMenu }) => (
    <a target="_blank" href="/" className="logo">
      <img
        className="logo-image"
        alt={companyConfig.name}
        src={image(negativeMenu ? LOGOS.BLUE : LOGOS.WHITE)}
      />
      <style jsx>{navBar}</style>
    </a>
)

export const LinkNeximo = () => (
    <a href="/" target="_blank">{companyConfig.name}</a>
)

export const IndexLink = () => (
    <a href="/" target="_blank" className="logo">
      Home
      <style jsx>{navBarMobile}</style>
    </a>
)

export const LinkJoin = () => (
  <a
    className="link"
    href={JOIN_US_URL}
    style={{ padding: '0.5rem' }}
  >
    Únete
  </a>
)

export const LinkSrp = () => (
  <a
    className="link"
    href="/en-venta"
    target="_blank"
    style={{ padding: '0.5rem' }}
  >
    Nuestras propiedades
  </a>
)

export const LinkTeam = () => (
  <a
    className="link"
    href="/equipo"
    target="_blank"
    style={{ padding: '0.5rem' }}
  >
    Directorio de Asesores
  </a>
)

export const MagazineLink = () => (
  <a
    className="link"
    target="_blank"
    rel="noopener"
    href={COMPANY_BLOG_URL}
    aria-label={`Revista ${companyConfig.name}`}
    style={{ padding: '0.5rem' }}
  >
    Revista
  </a>
)

export const AuthDashboardLink = ({ isAuthenticated }) =>
  isAuthenticated ? (
    <Link href="/dashboard" useNext={false}>
      Dashboard
    </Link>
  ) : null

// TODO: Also force a load of logout page
export const AuthLink = ({ isAuthenticated }) =>
  isAuthenticated ? (
    <Link href="/logout" as="/salir">
      Logout
    </Link>
  ) : (
    <Link href="/login" as="/ingresar" useNext={false}>
      Acceso
    </Link>
  )

export const ValuationLink = ({ hiddeValuationBtn }) => (
  <span className="valuation-btn">
    {!hiddeValuationBtn && (
      <ValuationLinkButton
        showSmallMessage
        onClick={() =>
          this.props.actions.sendGTEvent(
            'new-property-valuation',
            'header-link',
          )
        }
      />
    )}

    <style jsx>{navBar}</style>
  </span>
)

export const CountryLink = () => {
  return (
    <span className="link">
      <div className="subnav">
        <button className="subnavbtn">
          MX <IonIcon size="small" icon="ios-arrow-down" />
        </button>
        <div className="subnav-content">
          <a href="#">MX México</a>
          <a href="https://www.join-iad.com/" target="_blank">
            FR Francia
          </a>
        </div>
      </div>
      <style jsx>{countryMenu}</style>
    </span>
  )
}
