import { colors, fontSizes } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .nex-paginate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    list-style: none;
    width: 100%;
  }

  .nex-paginate-container a {
    color: ${colors.text};
    font-size: ${fontSizes.h4};
    padding: 0.3rem 1.8rem;
    cursor: pointer;
    user-select: none;
  }

  .nex-paginate-next:hover a,
  .nex-paginate-previous:hover a {
    color: ${colors.primary};
  }

  .nex-paginate-next,
  .nex-paginate-previous {
    color: ${colors.primary};
  }

  .next-paginate-container .disabled {
    cursor: not-allowed;
  }
  .disabled {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .nex-paginate-container a {
      padding: 0.8rem;
      font-size: ${fontSizes.body};
    }
  }
`

export const pageViewStyle = css`
  .nex-paginate-page .nex-paginate-link {
    color: ${colors.text};
    font-size: 1.5rem;
    padding: 0.3rem 1.8rem;
  }
  .nex-paginate-page:hover a.nex-paginate-link {
    cursor: pointer;
  }

  .nex-paginate-page:hover .nex-paginate-link {
    color: ${colors.primary};
  }

  .nex-paginate-page-active {
    border-radius: 0.4rem;
    background-color: ${colors.primary};
  }

  .nex-paginate-page-active .nex-paginate-link {
    color: ${colors.white};
  }

  .nex-paginate-page-active:hover .nex-paginate-link {
    color: ${colors.white};
  }

  @media screen and (max-width: 992px) {
    .nex-paginate-page .nex-paginate-link {
      padding: 0;
      font-size: ${fontSizes.body};
    }
  }
`

export const breackViewStyle = css`
  .break {
    color: ${colors.text};
  }
`
