import React, { Fragment, useEffect } from 'react'
import Link from 'next/link'
import classnames from 'classnames'

import { Button, IonIcon, Label } from 'controls'
import { removeAllEmptyKeys } from 'services/utils'

import { footerLinkAccordionStyles } from './styles'
import { footerLinkLabel } from 'services/seo'

const LinkContainer = ({
  label,
  count,
  href,
  showNextLevel,
  isActive,
  showMore,
  linksCount,
  propertyType,
  operationType,
  locationUrl,
  setShowMore
}) => (
  <li className="link-container">
    {linksCount > 0 && showNextLevel && (
      <Button
        size="tiny"
        title={`Mostrar más de ${label}`}
        onClick={() => setShowMore(showMore => !showMore)}
        className="link-container-show-more-btn"
      >
        <IonIcon icon={showMore ? 'md-remove' : 'md-add'} size="small" />
      </Button>
    )}

    <Link
      href={{
        pathname: '/srp',
        query: removeAllEmptyKeys({
          propertyType,
          operationType,
          location: locationUrl
        })
      }}
      as={href}
    >
      <a
        className={classnames('link', {
          'is-active': isActive,
          'with-offset': !linksCount || !showNextLevel
        })}
        onFocus={() => setShowMore(true)}
        aria-label={`${label} ver ${count || 0} resultados`}
        title={label}
      >
        {label}{' '}
        {count > 0 && (
          <Label size="small" className="link-count-label">
            {count}
          </Label>
        )}
      </a>
    </Link>

    <style jsx>{footerLinkAccordionStyles}</style>
  </li>
)

const ListFooterLinks = ({ links, levelsToShow, showMore }) => (
  <ul className={classnames('links-container', { 'show-more': showMore })}>
    {links.map(({ hrefConfig, href, ...rest }, index) => (
      <FooterLinkAccordion
        {...rest}
        key={href}
        href={href}
        levelsToShow={levelsToShow}
      />
    ))}
    <style jsx>{footerLinkAccordionStyles}</style>
  </ul>
)

const areEqual = (prevProps, nextProps) => {
  const p = prevProps
  const n = nextProps

  if (p.links.length !== n.links.length) {
    return false
  }

  if (n.links.find(({ href }, index) => href !== p.links[index].href)) {
    return false
  }

  return p.showMore === n.showMore
}

const ListFooterLinksMemo = React.memo(ListFooterLinks, areEqual)

const nextLevelShouldBeRendered = (links, levelsToShow) => {
  if (!links) {
    return false
  }

  const [firstLink] = links

  return firstLink && firstLink.level <= levelsToShow
}

const FooterLinkAccordion = ({
  label,
  isActive,
  links,
  href,
  count,
  propertyType,
  operationType,
  locationUrl,
  forceShowMore,
  levelsToShow,
  level
}) => {
  const [showMore, setShowMore] = React.useState(isActive || forceShowMore)

  useEffect(
    () => {
      setShowMore(isActive || forceShowMore)
    },
    [isActive, forceShowMore]
  )

  const showNextLevel = nextLevelShouldBeRendered(links, levelsToShow)

  return (
    <Fragment>
      {href && (
        <LinkContainer
          count={count}
          showNextLevel={showNextLevel}
          linksCount={(links && links.length) || 0}
          href={href}
          isActive={isActive}
          showMore={showMore}
          setShowMore={setShowMore}
          propertyType={propertyType}
          operationType={operationType}
          locationUrl={locationUrl}
          label={footerLinkLabel({
            level,
            levelsToShow,
            propertyType,
            operationType,
            locationLabel: label
          })}
        />
      )}

      {showNextLevel && (
        <ListFooterLinksMemo
          links={links}
          showMore={showMore}
          levelsToShow={levelsToShow}
        />
      )}

      <style jsx>{footerLinkAccordionStyles}</style>
    </Fragment>
  )
}

export default FooterLinkAccordion
