import React from 'react'
import PropTypes from 'prop-types'
import config, { checkFeatureFlag } from 'config'

/**
 * Component for dependencies based on a configuration file
 * config.json is in the rootApp
 * 
 * example:
 * {
 *    "featureFlags": {
 *        "layout-hero-search":{
 *            "disabled": true,
 *            "description":""
 *        },
 *    }
 * }
 */
const FeatureFlag = ({
  name, children = null, defaultChildren = null, evaluateIf = true
}) => {
  if (!evaluateIf) {
    return null
  }

  if (checkFeatureFlag(name)) {
    return children
  }

  return defaultChildren
}

FeatureFlag.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  defaultChildren: PropTypes.any,
  evaluateIf: PropTypes.bool
}

export default FeatureFlag
