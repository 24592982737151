import utils from 'services/utils'
import { titleize } from 'services/string'

/**
 * isLinkActive - To know if any url link match with the current page url
 * @param {String} linkUrlLocation - like "departamentosen-ventabosques-del-valle-1a-seccion--coacalco-de-berriozabal--mexico"
 * @param {Object} currentUrlsObjectLocation - like { municipality: "huixquilucan", neighborhood: "interlomas", state: "mexico" }
 * @returns {Boolean}
 */
export const isLinkActive = (linkUrlLocation, currentUrlsObjectLocation) => {
  const currentUrl = currentUrlsObjectLocation

  const { state, municipality, neighborhood } = utils.splitUrlByLocation(
    linkUrlLocation
  )

  const statesAreEqual = currentUrl.state === state
  const municipalitiesAreEqual = currentUrl.municipality === municipality
  const neighborhoodsAreEqual = currentUrl.neighborhood === neighborhood

  if (state && municipality && neighborhood) {
    return Boolean(statesAreEqual && municipalitiesAreEqual && neighborhoodsAreEqual)
  }

  if (state && municipality) {
    return Boolean(statesAreEqual && municipalitiesAreEqual)
  }

  return Boolean(statesAreEqual)
}

/**
 * getLinksTitle
 * In this moment we only make request with states, we don't use municipalities or neighborhoods
 * to get footer links, for that reason only paint 'Estados' or the state name.
 * @param {Object} params - comes from url, like params.state === 'ciudad-de-mexico'
 */
export const getLinksTitle = ({ state, municipality, neighborhood }) => {
  let title = 'Estados'

  if (state) {
    title = state
  }

  return titleize(title.split('-').join(' '))
}
