import React, { useMemo, Fragment } from 'react'
import SEOLink from 'components/SEO/SEOLink'

import config from 'config'

import JsonLD from 'components/SEO/JsonLD'
import { BASE_SRP_URL } from 'pagesConfig/utils/constants'

import getFormatedParamsValues, {
  getBiblioOfParams,
  formatParamsToString,
  formatParamsToLinks,
  getRightUrl
} from './getFormatedParamsValues'

import { breadcrumbStyles } from './styles'

const HOST_URL = config('HOST_URL')

export const getBreadcrumb = (filters, countResults) => {
  if (!filters) {
    return null
  }

  if (countResults === 0) {
    return 'resultados'
  }

  const params = getFormatedParamsValues(filters, countResults)

  return formatParamsToString(getBiblioOfParams(params))
}

const Breadcrumb = ({
  filters,
  count = '',
  showGoBackToSearchesButton,
  nextPathname = '/srp'
}) => {
  const { operationType = '', propertyType, selectedLocations } = filters

  const sl = selectedLocations && selectedLocations.length > 0 ? selectedLocations : ['']
  // useMemo requires that the order and size of array of dependencies not change between renders

  const memoizedBreadcrumb = useMemo(
    () =>
      formatParamsToLinks({
        showGoBackToSearchesButton,
        formatedParams: getBiblioOfParams(
          getFormatedParamsValues(filters, count)
        )
      }),
    [operationType, propertyType, ...sl, count]
  )

  return (
    <Fragment>
      {memoizedBreadcrumb.length > 0 && (
        <JsonLD
          data={{
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: memoizedBreadcrumb
              .filter(({ as }) => as)
              .map(({ as: path, label, linkPosition }) => ({
                '@type': 'ListItem',
                name: label,
                position: linkPosition,
                item: getRightUrl(HOST_URL + path)
              }))
          }}
        />
      )}

      <div className="srp-breadcrumb-wrapper">
        <ol>
          {showGoBackToSearchesButton && (
            <Fragment>
              <li>
                <SEOLink as={BASE_SRP_URL} href={{ pathname: nextPathname }}>
                  <a className="breadcrumb-link-to-go-back">
                    Regresar a la busqueda
                  </a>
                </SEOLink>
              </li>
            </Fragment>
          )}

          {memoizedBreadcrumb.map(({ label, query, as }, index) => (
            <li key={index}>
              <SEOLink
                as={as}
                key={index}
                href={{ query, pathname: nextPathname }}
              >
                <a className="link">{label}</a>
              </SEOLink>
            </li>
          ))}
        </ol>
        <style jsx>{breadcrumbStyles}</style>
      </div>
    </Fragment>
  )
}

export default Breadcrumb
