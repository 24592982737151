import React, { Fragment } from 'react'
import Spinner from 'controls/Spinner'
import { useSrpParams } from 'app/hooks'

import { SRP } from 'components/SEO/utils'

import { breadcrumbResultsStyle } from './styles'

const BreadcrumbResults = ({
  loadingData,
  count,
  operationType,
  handleApplyFilters
}) => {
  const srpParams = useSrpParams()

  return (
    <div className="breadcrumb-results-container">
      <div className="breadcrumb-results">
        <h1 className="title">
          {loadingData ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Spinner size={25} style={{ marginRight: '0.5rem' }} />
              <span className="loading-text">Cargando...</span>
            </div>
          ) : (
            <Fragment>
              <span className="breadcrumb-results-count">{count}</span>{' '}
              {SRP.baseTitle(srpParams)}
            </Fragment>
          )}
        </h1>
      </div>

      <style jsx>{breadcrumbResultsStyle}</style>
    </div>
  )
}

export default BreadcrumbResults
