import css from 'styled-jsx/css'
import { colors, fontWeights, measurements, fontSizes } from 'theme'

const IMAGE_HEIGHT = '250px' // It should be equal that value passed to PictureCropped at SRPCard

export default css`
  .srp-card {
    position: relative;
    text-align: left;
    max-width: ${measurements.containerSize};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-width: 15rem;
    transition: 0.3s all;
    background-color: ${colors.white};
    border-radius: ${measurements.borderRadius};

    display: flex;
    flex-direction: column;
  }

  .srp-card .header {
    display: flex;
    flex-direction: column-reverse;
  }

  .srp-card :global(img.srp-card-image.picture-cropped-srp-image),
  .srp-card :global(picture) {
    height: ${IMAGE_HEIGHT};
    min-height: ${IMAGE_HEIGHT};
    max-height: ${IMAGE_HEIGHT};
    border-top-left-radius: ${measurements.borderRadius};
    border-top-right-radius: ${measurements.borderRadius};
    cursor: pointer;
  }
  .srp-card :global(img.srp-card-image.picture-cropped-srp-image) {
    width: 100%;
  }

  .srp-card-title,
  .srp-card .srp-card-title-element {
    font-weight: ${fontWeights.semiBold};
    color: ${colors.text};
  }

  .srp-card-address {
    margin: 0;
    font-size: ${fontSizes.p};
  }

  .srp-card-price {
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.h5}
  }

  .is-small .srp-card-title,
  .srp-card-address,
  .srp-card .srp-card-link .srp-card-title-element {
    margin-bottom: 0.8rem;
  }

  .srp-card-ellipsis-text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 20.5rem;
    width: 100%;
  }

  .srp-card-title {
    width: 100%;
    border-right: 1px solid ${colors.border};
    border-left: 1px solid ${colors.border};
    padding: 1rem;
  }

  .is-small .srp-card-title {
    border: none;
    padding: 0;
  }

  .srp-card .srp-card-link .srp-card-title-element {
    margin: 0;
    font-size: ${fontSizes.h5};
  }

  .srp-card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem;
    padding-top: 0;
    border-bottom-left-radius: ${measurements.borderRadius};
    border-bottom-right-radius: ${measurements.borderRadius};
    border-left: 1px solid ${colors.border};
    border-bottom: 1px solid ${colors.border};
    border-right: 1px solid ${colors.border};
  }

  .srp-card.is-small,
  .srp-card.is-small :global(img.srp-card-image.picture-cropped-srp-image) {
    height: 13rem;
    max-height: 13rem;
    min-height: 13rem;
    border-bottom-left-radius: ${measurements.borderRadius};
    border-bottom-right-radius: ${measurements.borderRadius};
  }

  .srp-card.is-small .srp-card-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    height: 45%;
    border-bottom-left-radius: ${measurements.borderRadius};
    border-bottom-right-radius: ${measurements.borderRadius};
  }

  .srp-card.is-small .srp-card-title,
  .srp-card.is-small .srp-card-title-element,
  .srp-card.is-small .srp-card-address,
  .srp-card.is-small .srp-card .srp-card-link {
    margin-bottom: 0.3rem;
  }

  .srp-card.is-small .srp-card-title {
    position: absolute;
    bottom: 0;
    z-index: 10;
    padding: 0 1rem 0.5rem 1rem;
  }

  .srp-card.is-small .srp-card-content::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 1;
    background-image: linear-gradient( 180deg, transparent, rgba(0, 0, 0, 0.6) );
    border-bottom-left-radius: ${measurements.borderRadius};
    border-bottom-right-radius: ${measurements.borderRadius};
  }

  .srp-card.is-small .srp-card-title,
  .srp-card.is-small .srp-card-title-element,
  .srp-card.is-small .price-property-type-wrapper {
    color: ${colors.white};
    z-index: 10;
  }

`

export const specificationsWithoutIconsStyle = css`
  .specifications {
    margin-top: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 548px) {
    .specifications {
      display: flex;
      flex-direction: column;
    }
  }
`

export const specificationWithoutIconStyle = css`
  .specification {
    display: flex;
    flex-wrap: wrap;
    font-size: ${fontSizes.small};
    margin: 0 0.5rem 0.5rem 0;
  }

  .specification .title {
    color: ${colors.gray};
    margin-right: 0.5rem;
  }
  .specification .count {
    font-weight: ${fontWeights.semiBold};
    margin-left: 0.1rem;
    margin-right: 0.5rem;
  }
`
