import React, { Fragment } from 'react'
import SEOLink from 'components/SEO/SEOLink'

import { image } from 'services'
import { companyConfig } from 'globalConfigApp'

import { IonIcon } from 'controls'

import { footer as footerStyles } from './styles'

export const LinkedinLink = () => (
  <a
    href={companyConfig.linkedinUrl}
    target="_blank"
    rel="noopener"
    aria-label={`Linkedin ${companyConfig.name}`}
  >
    <IonIcon icon="logo-linkedin" className="footer-icons" />
  </a>
)

export const YoutubeLink = () => (
  <a
    href={companyConfig.youtubeUrl}
    target="_blank"
    rel="noopener"
    aria-label={`Youtube ${companyConfig.name}`}
  >
    <IonIcon icon="logo-youtube" className="footer-icons" />
  </a>
)

export const InstagramLink = () => (
  <a
    href={companyConfig.instagramUrl}
    target="_blank"
    rel="noopener"
    aria-label={`Instagram ${companyConfig.name}`}
  >
    <IonIcon icon="logo-instagram" className="footer-icons" />
  </a>
)

export const TermsLink = () => (
  <a href="/terminos-condiciones" target="_blank">
    Términos y Condiciones
  </a>
)

export const PrivacyLink = () => (
  <a href="/politica-privacidad" target="_blank">
    Políticas de Privacidad
  </a>
)

export const HelpLink = () => (
  <a href="/help/" target="_blank">
    Preguntas frecuentes
  </a>
)

export const Copyright = () => (
  <span>
    © {companyConfig.name} {new Date().getFullYear()}, Todos los derechos
    reservados
  </span>
)

export const ContrySource = () => (
  <p className="footer-copyright">
    Hecho en México
    <style jsx>{footerStyles}</style>
  </p>
)

export const ContactUsLink = () => (
  <a href="/contactanos" target="_blank">
    Contáctanos
  </a>
)

export const FooterLogo = () => (
  <Fragment>
    <img
      width="192"
      height="32"
      className="logo-img"
      alt={`${companyConfig.name} partners`}
      src={image('logo-partners-IAD.png')}
    />

    <style jsx>{footerStyles}</style>
  </Fragment>
)

export const SocialMediaLinks = () => (
  <div className="social-media-links">
    <LinkedinLink />
    <YoutubeLink />
    <InstagramLink />
    <style jsx>{footerStyles}</style>
  </div>
)

export const HelpSection = () => (
  <div className="help-section">
    <HelpLink />
    |
    <ContactUsLink />
    <style jsx>{footerStyles}</style>
  </div>
)

export const TermsSection = () => (
  <div className="terms-section">
    <TermsLink />
    |
    <PrivacyLink />
    <style jsx>{footerStyles}</style>
  </div>
)
