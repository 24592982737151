import css from 'styled-jsx/css'
import { colors, fontSizes, fontWeights } from 'theme'

import { FOOTER_COLUMN_WIDTH } from './config'

export const categoriesFooterStyles = css`
  .categories-footer {
    padding: 0 1.5rem;
    width: 100%;
  }
  .categories-footer .title {
    font-weight: ${fontWeights.bold};
    margin-bottom: 0.5rem;
  }
  .categories-footer-links {
    display: flex;
    flex-wrap: wrap;
  }
  .categories-footer .controls {
    color: ${colors.primary};
    margin-top: 1rem;
  }

  .categories-footer .controls :global(i) {
    font-size: ${fontSizes.p};
  }

  .categories-footer.vertical {
    margin: 0;
  }

  .categories-footer-links.not-general-title
    :global(.category-footer:first-child .category-footer-list) {
    border-top: none;
  }

  @media screen and (max-width: 992px) {
    .categories-footer {
      padding: 0;
    }
    .categories-footer-links {
      flex-direction: column;
    }
  }
`

export const categoryFooterStyles = css`
  .category-footer {
    width: max-content;
    margin-right: 1.5rem;
  }
  .category-footer-list {
    margin-top: 0;

    height: 11rem;
    overflow-y: hidden;
    transition: all 0.2s;
    list-style: none;

    margin: 0;
    padding: 0;
  }
  .category-footer .categoty-title {
    margin: 0;
    font-size: ${fontSizes.p};
    font-weight: ${fontWeights.semiBold};
    line-height: 3rem;
    height: 3rem;
  }

  .category-footer .wrapper-link {
    margin-left: 0;

    line-height: 1.5rem;
    height: 1.5rem;
  }

  .category-footer .wrapper-link .count {
    font-weight: ${fontWeights.bold};
  }
  .category-footer .wrapper-link a {
    color: ${colors.alpha(colors.text, 0.6)};
    font-size: ${fontSizes.small};
  }

  .category-footer .wrapper-link a:hover {
    color: ${colors.primary};
    text-decoration: underline;
  }

  .category-footer .wrapper-link a:visited {
    color: ${colors.secondary};
    text-decoration: underline;
  }

  .category-footer.vertical {
    width: 100%;
    margin-right: 0;
  }

  .category-footer.is-full-open .category-footer-list {
    height: initial;
  }

  @media screen and (max-width: 992px) {
    .category-footer {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      margin-top: 2rem;
    }

    .category-footer .categoty-title {
      margin-top: 1rem;
    }

    .category-footer .category-footer-list {
      padding: 0 1rem;
    }
    .category-footer .wrapper-link {
      padding: 0.3rem 0;
      line-height: 1.8rem;
      height: 1.8rem;

      overflow: hidden;
    }
  }
`

export const footerLinkAccordionStyles = css`
  .footer-link-accordion-container {
    width: 100%;
  }
  .link-container {
    display: flex;
    align-items: center;
  }
  .link-container :global(> button.link-container-show-more-btn) {
    height: fit-content;
    width: fit-content;
    padding: 0 0.2rem;
    margin: 0 0.5rem;
  }
  .link-container :global(> button.link-container-show-more-btn i) {
    margin: 0;
  }

  .link-container .link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: ${fontSizes.p};

    color: ${colors.text};
  }
  .link-container .link.with-offset {
    margin-left: 1.5rem;
  }

  .link-container .link.is-active,
  .link-container .link.is-active > :global(.link-count-label) {
    color: ${colors.primary};
    font-weight: ${fontWeights.semiBold};
  }

  .link-container .link > :global(.link-count-label) {
    margin-left: 0.5rem;
    background-color: ${colors.alpha(colors.gray, 0.3)};
  }
  .link-container .link:hover,
  .link-container .link:hover > :global(.link-count-label) {
    color: ${colors.primary};
  }

  .links-container {
    height: 0;
    overflow: hidden;
    padding-left: 0;
    margin: 0;
  }
  .links-container li {
    list-style: none;
    padding: 0.2rem;
  }
  .links-container .links-container {
    padding-left: 1rem;
  }
  .links-container .links-container li {
    padding-left: 0;
    padding-right: 0;
  }
  .links-container.show-more {
    height: auto;
  }

  @media screen and (max-width: 992px) {
    .links-container li {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }
`

export const statesFooterLinksStyles = css`
  .state-footer-links-container {
    margin: 0.5rem;
  }
  .state-footer-links-container .links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${FOOTER_COLUMN_WIDTH}, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
  }

  @media screen and (max-width: 768px) {
    .state-footer-links-container .links {
      grid-row-gap: 0;
    }
  }
`

export const amenitiesLinksStyles = css`
  .container {
    margin: 0.5rem;
    display: block;
  }

  @media screen and (max-width: 768px) {
    .container {
      max-width: 21.875rem;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
`

export const amenitiesListStyles = css`
  .container {
    margin-top: 1.5rem;
    border-top: 1px solid ${colors.border};
  }

  .amenity-list {
    display: flex; 
    flex-wrap: wrap;
    margin-left: 0.5rem;
  }

  .title {
    margin: 1.5rem 0;
    font-weight: ${fontWeights.semiBold};
  }

  @media screen and (max-width: 768px) {
    .amenity-list {
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
    }
  }
`
