import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import PageView from './PageView'
import BreakView from './BreakView'
import styles from './styles'

export default class NextPaginate extends Component {
  handlePageSelected = selected => {
    const { selected: currentSelected } = this.props

    if (currentSelected === selected) {
      return
    }

    this.props.onPageChange(selected)
  }

  handlePreviousPage = evt => {
    const { selected } = this.props
    if (selected > 0) {
      this.props.onPageChange(selected - 1)
    }
  }

  handleNextPage = evt => {
    const { selected, pageCount } = this.props

    if (selected < pageCount - 1) {
      this.props.onPageChange(selected + 1)
    }
  }

  getPageElement = index => {
    const { selected, size } = this.props

    return (
      <PageView
        size={size}
        key={index}
        onClick={() => this.handlePageSelected(index)}
        selected={selected === index}
        page={index + 1}
      />
    )
  }

  pagination = () => {
    const items = []
    const {
      pageRangeDisplayed,
      pageCount,
      marginPagesDisplayed,
      breakLabel,
      breakClassName,
      selected
    } = this.props

    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        items.push(this.getPageElement(index))
      }
    } else {
      let leftSide = pageRangeDisplayed / 2
      let rightSide = pageRangeDisplayed - leftSide

      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected
        leftSide = pageRangeDisplayed - rightSide
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected
        rightSide = pageRangeDisplayed - leftSide
      }

      let index
      let page
      let breakView
      let createPageView = index => this.getPageElement(index)

      for (index = 0; index < pageCount; index++) {
        page = index + 1

        if (page <= marginPagesDisplayed) {
          items.push(createPageView(index))
          continue
        }

        if (page > pageCount - marginPagesDisplayed) {
          items.push(createPageView(index))
          continue
        }

        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(createPageView(index))
          continue
        }

        if (breakLabel && items[items.length - 1] !== breakView) {
          breakView = (
            <BreakView // Son los 3 puntitos
              key={index}
              breakLabel={breakLabel}
              breakClassName={breakClassName}
            />
          )
          items.push(breakView)
        }
      }
    }
    return items
  }

  render() {
    const {
      size,
      pageCount,
      previousLinkClassName,
      previousLabel,
      nextLinkClassName,
      nextLabel,
      selected
    } = this.props

    const previousClasses = selected === 0 ? ` ${'disabled'}` : ''
    let nextClasses = selected === Math.ceil(pageCount) - 1 ? ` ${'disabled'}` : ''

    if (pageCount < 1) {
      return null
    }

    return (
      <ul className={classnames('nex-paginate-container', size)}>
        <li className={previousClasses}>
          <a
            onClick={this.handlePreviousPage}
            className={previousLinkClassName}
            tabIndex="0"
            role="button"
            onKeyPress={this.handlePreviousPage}
          >
            {previousLabel}
          </a>
        </li>

        {this.pagination()}

        <li className={nextClasses}>
          <a
            onClick={this.handleNextPage}
            className={nextLinkClassName}
            tabIndex="0"
            role="button"
            onKeyPress={this.handleNextPage}
          >
            {nextLabel}
          </a>
        </li>
        <style jsx>{styles}</style>
      </ul>
    )
  }
}

NextPaginate.defaultProps = {
  selected: 0, // CURRENT PAGE - Es el index del paginador, empezando en cero.
  onPageChange: index => {}, // Recibe el index del paginador
  pageCount: 10,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 2,
  previousLabel: 'Anterior',
  nextLabel: 'Siguiente',
  breakLabel: '...',
  size: 'normal',
}

NextPaginate.propTypes = {
  selected: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  previousLabel: PropTypes.any,
  nextLabel: PropTypes.any,
  breakLabel: PropTypes.any,
  size: PropTypes.oneOf(['normal', 'small'])
}
