import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classnames from 'classnames'

import { SrpActionCreators } from 'app/reducers/SrpRedux'
import { MinMaxPriceDropDown } from 'components/Dropdowns/PriceDropdown'

import PropertyFiltersContainer, {
  SectionControlFilter,
} from 'components/PropertyFilters'
import {
  PropertyTypeFilter,
  BedroomsFilter,
  BathroomsFilter,
  ParkingSpacesFilter,
  AmenitiesFilter,
} from 'components/PropertyFilters/FilterControls'
import OperationTypeStwichFilters from 'components/Srp/SearchResults/FilterControls'

import Location from 'components/PropertyFilters/Location'
import FilterList from 'components/PropertyFilters/FilterList'

import { getNexIds } from 'components/PropertyFilters/config'

import { sanitizeSRPFilters } from 'app/selectors/srp'

class SRPFilters extends Component {
  static propTypes = {
    filters: PropTypes.shape({
      propertyType: PropTypes.string,
      operationType: PropTypes.string,
      locations: PropTypes.array,
      age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bedrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bathrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      halfBathrooms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      parkingSpaces: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      minPrice: PropTypes.string,
      maxPrice: PropTypes.string,
      selectedLocations: PropTypes.array,
      activePlan: PropTypes.any,
      saleType: PropTypes.string,
      isADevelopment: PropTypes.string,
      developmentType: PropTypes.string,
    }).isRequired,
    applyFilters: PropTypes.func.isRequired,
    multipleNeighborhood: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      updateFilters: PropTypes.func.isRequired,
    }).isRequired,
  }

  state = {
    currentStateId: null,
    currentMunicipalityId: null,
    currentNeighborhoodId: null,
  }

  changeFilter = e => {
    const { applyFilters, filters } = this.props
    applyFilters({
      ...filters,
      [e.target.name]: e.target.value,
    })
  }

  handleRemoveFilter = newFilters => {
    const { applyFilters } = this.props
    applyFilters(newFilters)
  }

  handleLocationsChange = selectedLocations => {
    const { applyFilters, filters } = this.props
    applyFilters({
      ...filters,
      selectedLocations,
    })
  }

  handleUpdateFilters = e => {
    const {
      actions: { updateFilters },
    } = this.props
    updateFilters({ [e.target.name]: e.target.value })
  }

  handleApplyAmenityFilters = amenities => {
    this.handleApplyNewFilter({
      scrollToTop: false,
      ...amenities,
    })
  }

  handleCloseAmenities = isOpen => {
    if (!isOpen) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }

  handleApplyNewFilter = newFilters => {
    const { applyFilters, filters } = this.props
    applyFilters({
      ...filters,
      ...newFilters,
    })
  }

  render() {
    const {
      filters,
      multipleNeighborhood,
      customContainerClass,
      className,
      showAmenitiesFilter,
      customComponentsClass,
    } = this.props

    return (
      <PropertyFiltersContainer
        className={classnames('filter-srp', className, customContainerClass)}
      >
        <FilterList
          filters={{
            ...filters,
            operationType: null,
            filtersId: getNexIds(filters.id),
          }}
          onRemoveFilter={newFilters =>
            this.handleRemoveFilter({
              ...newFilters,
              operationType: filters.operationType,
            })
          }
          customContainerClass={customContainerClass}
          customComponentsClass={customComponentsClass}
        />

        <SectionControlFilter title="Inmueble y operación">
          <OperationTypeStwichFilters
            operationType={filters.operationType}
            handleApplyFilters={operationType =>
              this.handleApplyNewFilter({ operationType })
            }
          />
          <PropertyTypeFilter
            value={filters.propertyType || ''}
            onChange={this.changeFilter}
            className={customComponentsClass}
          />
        </SectionControlFilter>

        <Location
          filters={filters}
          onLocationsChange={this.handleLocationsChange}
          multipleNeighborhood={multipleNeighborhood}
        />

        <SectionControlFilter title="Caracteristicas">
          <BedroomsFilter
            value={filters.bedrooms || ''}
            onChange={this.changeFilter}
            className={customComponentsClass}
          />

          <BathroomsFilter
            value={filters.bathrooms || ''}
            onChange={this.changeFilter}
            className={customComponentsClass}
          />

          <ParkingSpacesFilter
            value={filters.parkingSpaces || ''}
            onChange={this.changeFilter}
            className={customComponentsClass}
          />

          <MinMaxPriceDropDown
            onChange={this.handleApplyNewFilter}
            minPrice={filters.minPrice}
            maxPrice={filters.maxPrice}
          />

          <AmenitiesFilter
            value={filters}
            showAmenitiesFilter={showAmenitiesFilter}
            onChange={this.handleApplyAmenityFilters}
            handleToggleOpen={this.handleCloseAmenities}
          />
        </SectionControlFilter>
      </PropertyFiltersContainer>
    )
  }
}

const mapStateToProps = (state, props) => {
  const filters = props.filters ? props.filters : state.srp.filters

  return {
    filters: sanitizeSRPFilters(filters),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        updateFilters: ownProps.updateFilters
          ? ownProps.updateFilters
          : SrpActionCreators.updateFilters,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SRPFilters)
