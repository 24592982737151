import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { container } from './styles'

const Container = ({ children, className, styles = {}, asComponent = "div" }) => {
  const Component = asComponent

  return (
    <Component
      className={classnames(className, 'container app-wrapper-container')}
      style={styles}
    >
      {children}
      <style jsx>{container}</style>
    </Component>
  )
}

Container.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  styles: PropTypes.string
}

export default Container
