import React from 'react'

import { footer } from './styles'

import {
  ContrySource,
  Copyright,
  FooterLogo,
  SocialMediaLinks,
  HelpSection,
  TermsSection
} from './SocialLinks'

import SeoFooter from './SeoFooter'

const Footer = () => (
  <div className="footer-container">
    <div className="app-wrapper-container">
      <SeoFooter bottomDivider />

      <div className="footer">
        <div className="footer-section">
          <ContrySource />
          <Copyright />
          <br />
          <TermsSection />
        </div>

        <div className="footer-section footer-logo">
          <FooterLogo />
        </div>
        <div className="footer-section">
          <SocialMediaLinks />

          <HelpSection />
        </div>
      </div>
    </div>
    <style jsx>{footer}</style>
  </div>
)

export default Footer
