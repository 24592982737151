import { getFiltersFromUrl } from 'services/seo'
import { camelToSnake } from 'services/string'
import { SEARCH_CONFIG } from 'components/Srp'

export const parseParams = ({ query }) => {
  const filters = getFiltersFromUrl(query)
  let offset = 0
  const page = query.page || 1
  offset = (parseInt(page) - 1) * SEARCH_CONFIG.limit

  const fullFilters = {
    limit: SEARCH_CONFIG.limit,
    ...filters,
    offset
  }

  let snakeParams = {}
  Object.keys(fullFilters).forEach(k => {
    if (fullFilters[k]) {
      snakeParams[camelToSnake(k)] = fullFilters[k]
    }
  })

  let location = {}

  if (filters.location) {
    const [state, municipality, neighborhood] = filters.location
      .split('--')
      .reverse()
      .map(l => l.split('-').join(' '))

    location.state = state
  }

  return {
    fullFilters,
    snakeParams,
    location
  }
}