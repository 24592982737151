import css from 'styled-jsx/css'
import { colors, fontWeights, measurements } from 'theme'

export const propertyCarouselStyles = css`
  .property-carousel-title {
    margin-bottom: 0.5rem;
  }

  :global(.carousel-container-buttons.property-carousel) {
    margin-bottom: 3rem;
  }

  :global(.property-carousel-container.property-carousel .srp-card) {
    width: 20rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  @media screen and (min-width: 1200px) {
    :global(.property-carousel-container.property-carousel .srp-card),
    :global(.property-carousel-container.property-carousel .srp-card
      img.srp-card-image.picture-cropped-srp-image) {
      max-width: 21rem;
      width: 21rem;
    }
  }
`

export const carouselStyles = css`
  .carousel-container-buttons {
    position: relative;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .carousel-container-buttons button.right-button,
  .carousel-container-buttons button.left-button {
    position: absolute;
    top: 0;
    z-index: 10;
    border: none;
    height: 100%;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
  .carousel-container-buttons button.right-button:focus .circle,
  .carousel-container-buttons button.left-button:focus .circle {
    background-color: ${colors.text};
  }

  .carousel-container-buttons button.right-button:focus .circle :global(i),
  .carousel-container-buttons button.left-button:focus .circle :global(i){
    color: ${colors.white};
  }

  .carousel-container-buttons button.right-button .circle,
  .carousel-container-buttons button.left-button .circle {
    border-radius: 50%;
    background-color: ${colors.white};
    height: 2.7rem;
    width: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel-container-buttons button.right-button.secondary .circle,
  .carousel-container-buttons button.left-button.secondary  .circle {
    background-color: ${colors.text};
    color: ${colors.white};
  }
  .carousel-container-buttons button.right-button.secondary:focus .circle,
  .carousel-container-buttons button.left-button.secondary:focus .circle {
    background-color: ${colors.white};
  }
  .carousel-container-buttons button.right-button.secondary:focus .circle :global(i),
  .carousel-container-buttons button.left-button.secondary:focus .circle :global(i){
    color: ${colors.text};
    font-size: 1.3rem;
  }

  .carousel-container-buttons button.right-button {
    right: -4rem;
  }
  .carousel-container-buttons button.left-button {
    left: -4rem;
  }

  .carousel-container,
  .carousel {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
    margin-top: 1rem;
    margin-bottom: 1rem;
    transition: all 0.3s;
  }

  .carousel {
    min-width: fit-content;
    overflow-y: hidden;
  }

  .carousel-container-buttons.overElemens  button.right-button,
  .carousel-container-buttons.overElemens  button.left-button {
    width: 4rem;
  }
  .carousel-container-buttons.overElemens button.right-button {
    right: -2.5rem;
  }
  .carousel-container-buttons.overElemens button.left-button {
    left: -1.5rem;
  }

  @media screen and (max-width: 1450px) {
    .carousel-container {
      margin: 1rem;
    }

    .carousel-container-buttons.overElemens button.right-button,
    .carousel-container-buttons button.right-button {
      right: 0rem;
    }
    .carousel-container-buttons.overElemens button.left-button,
    .carousel-container-buttons button.left-button {
      left: 0rem;
    }

    .carousel-container-buttons.overElemens button.right-button,
    .carousel-container-buttons.overElemens button.left-button,
    .carousel-container-buttons button.right-button,
    .carousel-container-buttons button.left-button {
      width: 4rem;
    }
    .carousel-container-buttons button.right-button .circle {
      margin-left: auto;
    }
  }
`
