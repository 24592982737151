import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Label } from 'controls'
import SimpleAlert from 'components/Alert/SimpleAlert'

import { tagOcupationStatusStyles } from './styles'

const TagOcupationStatus = ({ status, position }) => {
  if (!status) {
    return null
  }

  return (
    <Label
      className={classnames("tag-ocupation-status-labe-wrapper",  `position-${position}`)}
      color={status.labelColor}
      size="small"
    >
      <p className="label">
        <strong>{status.label}</strong>
      </p>
      <style jsx>{tagOcupationStatusStyles}</style>
    </Label>
  )
}

TagOcupationStatus.defaultProps = {
  position: 'top-left',
}

TagOcupationStatus.propTypes = {
  status: PropTypes.shape({
    labelColor: PropTypes.string,
    label: PropTypes.string,
  }),
  position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right'])
}

const MESSAGES = {
  'RENT': 'ya fue rentada',
  'SELL': 'ya fue vendida',
  'CLAIMED': 'está en proceso de cierre'
}

export const AlertOcupationStatus = ({ status }) => {
  if (!status) {
    return null
  }

  const message = MESSAGES[status.value]

  return (
    <SimpleAlert type={status.alertColor}>
      La propiedad que buscas {message}
    </SimpleAlert>
  )
}

AlertOcupationStatus.propTypes = {
  status: PropTypes.shape({
    label: PropTypes.string,
    alertColor: PropTypes.oneOf(['danger', 'purple'])
  })
}

export default TagOcupationStatus
