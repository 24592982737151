import React from 'react'

import { Button } from 'controls'

import { filterControlsStyles } from './styles'

const FilterButton = ({
  label,
  className,
  operationType,
  currentOperationType,
  handleClick
}) => (
  <Button
    size="tiny"
    color="primary"
    className={className}
    outline={!(currentOperationType && currentOperationType.includes(operationType))}
    onClick={() => handleClick(operationType)}
  >
    {label}
  </Button>
)

const FilterControls = ({ operationType, handleApplyFilters }) => (
  <div className="srp-filter-controls-wrapper">
    <FilterButton
      label="Venta"
      operationType="SELL"
      currentOperationType={operationType}
      className="filter-control sell-button"
      handleClick={handleApplyFilters}
    />
    <FilterButton
      label="Renta"
      operationType="RENT"
      currentOperationType={operationType}
      className="filter-control rent-button"
      handleClick={handleApplyFilters}
    />

    <style jsx>{filterControlsStyles}</style>
  </div>
)

export default FilterControls
