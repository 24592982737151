import React from 'react'
import classnames from 'classnames'

import { Spinner } from 'controls'
import SRPCard from 'components/SRPCard'

import BreadcrumbResults from './BreadcrumbResults'
import { srpResultsContainer } from './styles'
import { image } from 'services'

const LoadingSrpCards = ({ loading, cardsCount = 3, isIndexPage }) => {
  if (loading) {
    return (
      <div
        className={classnames('srp-results-container', {
          'is-index-page': isIndexPage
        })}
      >
        {new Array(cardsCount).fill(1).map((_, i) => (
          <div className="loading-srp-card" key={i}>
            <Spinner />
            <SRPCard vertical loading key={i} className="srp-property-card" />
          </div>
        ))}
        <style jsx>{srpResultsContainer}</style>
      </div>
    )
  }

  return null
}

const SRPNotResults = () => (
  <div>
    <h2>No hallamos lo que buscabas</h2>
    <img src={image('/property/not-results.gif')} style={{ width: '100%', maxWidth: '100%' }} />
  </div>
)

const Results = ({
  count,
  results,
  loadingData,
  filters,
  applyFilters,
  isIndexPage,
  showBreadcrumb = false
}) => {
  const { operationType = '' } = filters

  const handleApplyFilters = operationType =>
    applyFilters({
      ...filters,
      operationType
    })

  return (
    <div className="filter-controls-wrapper">
      {showBreadcrumb && (
        <BreadcrumbResults
          loadingData={loadingData}
          count={count}
          filters={filters}
          operationType={operationType}
          handleApplyFilters={handleApplyFilters}
        />
      )}

      {!loadingData && !count && <SRPNotResults />}

      <div
        className={classnames('srp-results-container', {
          'is-index-page': isIndexPage
        })}
      >
        {!loadingData && results.map((propertyData, i) => (
          <SRPCard
            vertical
            key={i}
            propertyData={propertyData}
            loading={loadingData}
            className="srp-property-card"
          />
        ))}
      </div>

      <LoadingSrpCards loading={loadingData} isIndexPage={isIndexPage} />

      <style jsx>{srpResultsContainer}</style>
    </div>
  )
}

export default Results
