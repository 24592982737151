import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useSrpParams } from 'app/hooks'

import {
  amenitiesBySelector,
  amenitiesBy as getAmenitiesBy
} from 'selectors/amenities'
import { getNumericAmenities } from 'services/seo/amenities'

import Container from 'components/Layout/Container'
import SRPLink from 'components/Srp/SRPLink'
import { amenitiesLinksStyles, amenitiesListStyles } from './styles'

const AmenityList = ({ amenities }) => (
  <div className="amenity-list">
    {amenities.map(({ id, name, fieldName, amenityValue }) => {
      if (!fieldName) {
        return null
      }
      const currentValue = amenityValue[fieldName]

      return (
        <SRPLink
          key={id || name}
          fieldName={fieldName}
          currentValue={currentValue}
          label={name}
        />
      )
    })}

    <style jsx>{amenitiesListStyles}</style>
  </div>
)

AmenityList.propTypes = {
  amenities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired, // like recamaras
      fieldName: PropTypes.string, // like bedrooms
      amenityValue: PropTypes.object // like { bedrooms: 3 } or { gym: true }
    })
  )
}

const numericAmenities = getNumericAmenities()

const NumericAmenitiesList = ({ srpParams, currentPropertyType }) => {
  return numericAmenities.map(numericAmenity => {
    const amenitiesBy = getAmenitiesBy(numericAmenity.amenities)
    const amenities =
      amenitiesBy.PROPERTY_TYPES[currentPropertyType] || amenitiesBy.ALL

    if (amenities.length > 0) {
      return (
        <div key={numericAmenity.name} className="container">
          <h5 className="title">{numericAmenity.name}</h5>
          <AmenityList amenities={amenities} srpParams={srpParams} />
          <style jsx>{amenitiesListStyles}</style>
        </div>
      )
    }
    return null
  })
}

const AmenitiesLinks = () => {
  const srpParams = useSrpParams()
  const amenitiesBy = useSelector(amenitiesBySelector)
  const currentPropertyType = srpParams.propertyType?.toUpperCase()
  const amenities =
    amenitiesBy.PROPERTY_TYPES[currentPropertyType] || amenitiesBy.ALL

  return (
    <Container asComponent="section">
      <div className="container">
        {amenities.length > 0 && <h4 className="title">Amenidades</h4>}
        <AmenityList amenities={amenities} srpParams={srpParams} />
        <NumericAmenitiesList
          currentPropertyType={currentPropertyType}
          srpParams={srpParams}
        />
      </div>
      <style jsx>{amenitiesLinksStyles}</style>
    </Container>
  )
}

export default AmenitiesLinks
