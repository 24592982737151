import { OPERATION_TYPES, PROPERTY_TYPES } from 'app/services/seo'
import { DROPDOWN_AMENITIES } from 'services/seo/amenities'
import { DropDownCheckbox } from 'controls'
import { SimpleDropDown } from 'controls/DropDown'
import FeatureFlag from 'components/FeatureFlag'

import { NUMBER_FILTER } from 'components/PropertyFilters/config'

export function OperationTypeFilter({ value, ...rest }) {
  return (
    <SimpleDropDown
      name="operationType"
      value={value || ''}
      placeholder="Tipo de Operación"
      {...rest}
    >
      {OPERATION_TYPES.map(({ value, label }, i) => (
        <option value={value} key={i}>
          {label}
        </option>
      ))}
    </SimpleDropDown>
  )
}

export function PropertyTypeFilter({ value, ...rest }) {
  return (
    <SimpleDropDown
      name="propertyType"
      value={value || ''}
      placeholder="Tipo de Propiedad"
      {...rest}
    >
      {PROPERTY_TYPES.map(({ value, label }, i) => (
        <option value={value} key={i}>
          {label}
        </option>
      ))}
    </SimpleDropDown>
  )
}

export function BedroomsFilter({ value, ...rest }) {
  return (
    <SimpleDropDown
      name="bedrooms"
      value={value || ''}
      placeholder="Habitaciones"
      label="Habitaciones"
      {...rest}
    >
      {NUMBER_FILTER.map(({ value, label }, i) => (
        <option value={value} key={i}>
          {label}
        </option>
      ))}
    </SimpleDropDown>
  )
}

export function BathroomsFilter({ value, ...rest }) {
  return (
    <SimpleDropDown
      name="bathrooms"
      value={value || ''}
      placeholder="Baños"
      label="Baños"
      {...rest}
    >
      {NUMBER_FILTER.map(({ value, label }, i) => (
        <option value={value} key={i}>
          {label}
        </option>
      ))}
    </SimpleDropDown>
  )
}

export function ParkingSpacesFilter({ value, ...rest }) {
  return (
    <SimpleDropDown
      name="parkingSpaces"
      value={value || ''}
      placeholder="Estacionamientos"
      label="Estacionamientos"
      {...rest}
    >
      {NUMBER_FILTER.map(({ value, label }, i) => (
        <option value={value} key={i}>
          {label}
        </option>
      ))}
    </SimpleDropDown>
  )
}

export function AmenitiesFilter({ value, showAmenitiesFilter, ...rest }) {
  if (!showAmenitiesFilter) {
    return null
  }

  return (
    <FeatureFlag name="seo-amenities">
      <DropDownCheckbox
        label="Amenidades"
        options={DROPDOWN_AMENITIES}
        value={value}
        {...rest}
      />
    </FeatureFlag>
  )
}
