import React from 'react'
import PropTypes from 'prop-types'
import { IonIcon, Button } from 'controls'

const BaseValuationButton = ({ children, ...rest }) => (
  <a href="/valuation/new/property" target="_blank">
    <Button size="tiny" color="primary" styleType="rounded">
      <IonIcon size="small" icon="md-home" /> {children}
    </Button>
  </a>
)

const LinkButton = ({ renderAsLink, showSmallMessage, ...rest }) => {
  const content = showSmallMessage ? 'Vender/Rentar' : 'Quiero Vender o Rentar'

  if (renderAsLink) {
    return <BaseValuationButton {...rest}>{content}</BaseValuationButton>
  }

  return <BaseValuationButton {...rest}>{content}</BaseValuationButton>
}

LinkButton.propTypes = {
  showSmallMessage: PropTypes.bool,
  renderAsLink: PropTypes.bool,
}

export default LinkButton
