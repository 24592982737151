import React from 'react'
import classnames from 'classnames'
import { pageViewStyle } from './styles'

const PageView = ({ size, onClick, page, selected }) => {
  return (
    <li
      className={classnames('nex-paginate-page', size, {
        'nex-paginate-page-active': selected
      })}
    >
      <a
        onClick={onClick}
        role="button"
        className="nex-paginate-link"
        tabIndex="0"
        onKeyPress={onClick}
      >
        {page}
      </a>
      <style jsx>{pageViewStyle}</style>
    </li>
  )
}

export default PageView
