import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import SimpleCarousel from 'components/Carousel/SimpleCarousel'

import { propertyCarouselStyles } from './styles'

const PropertyCarousel = ({ title = 'Title', children }) => (
  <Fragment>
    <h2 className="property-carousel-title">{title}</h2>
    <SimpleCarousel
      offset={336}
      className="property-carousel-container property-carousel"
      buttonsColor="secondary"
      buttonsPosition="overElemens"
    >
      {children}
    </SimpleCarousel>

    <style jsx>{propertyCarouselStyles}</style>
  </Fragment>
)

PropertyCarousel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number])
  ).isRequired
}

export default PropertyCarousel
