import React from 'react'
import classnames from 'classnames'

import { image } from 'services'

import {
  specifications as specificationsStyles,
  specification as specificationStyle,
} from './styles'

export const SPECIFICATION_TYPES = {
  home: ['bedrooms', 'bathrooms', 'parkingSpaces', 'surfaceArea'],
}

export const defaultConfig = {
  bedrooms: {
    icon: '/icons/amenities/icono-recamara.svg',
    whiteIcon: '/icons/amenities/icono-recamara-white.svg',
    text: 'Recámaras-Cuartos',
    srpText: 'Recámaras',
    srpTextSingular: 'Recámara'
  },
  bathrooms: {
    icon: '/icons/amenities/icono-banos.svg',
    whiteIcon: '/icons/amenities/icono-banos-white.svg',
    text: 'Baños',
    srpText: 'Baños',
    srpTextSingular: 'Baño'
  },
  halfBathrooms: {
    icon: '/icons/amenities/icono-medio-bano.svg',
    whiteIcon: 'icono-medio-bano-white.svg',
    text: 'Medios baños'
  },
  parkingSpaces: {
    icon: '/icons/amenities/icono-estacionamientos-2.svg',
    whiteIcon: '/icons/amenities/icono-estacionamiento-white.svg',
    text: 'Prkg',
    srpText: 'Estacionamientos',
    srpTextSingular: 'Estacionamiento'
  },
  constructionArea: {
    icon: '/icons/amenities/icono-construccion.svg',
    text: 'M² Construcción',
    srpText: 'M² Construcción',
  },
  surfaceArea: {
    icon: '/icons/amenities/icono-terreno.svg',
    whiteIcon: '/icons/amenities/icono-surface-area-white.svg',
    text: 'M² Terreno',
    srpText: 'M² Terreno',
  },
  age: {
    icon: '/icons/amenities/icono-antiguedad.svg',
    whiteIcon: '/icons/amenities/icono-antiguedad-white.svg',
    text: 'Antigüedad',
    srpText: 'Antigüedad',
  }
}

const SIZE_IMG_SPEC = {
  // Sizes should be in px
  'small': {
    height: 36.8,
    width: 12.8
  },
  'normal': {
    height: 36,
    width: 16
  }
}

const hasSize = (classNameSize = "") => ['small'].includes(classNameSize)

const Specification = ({ specification, withWhiteIcon, className = "" }) => {
  const { width, height } = hasSize(className)
    ? SIZE_IMG_SPEC[className]
    : SIZE_IMG_SPEC['normal']

  return (
    <div
      className={classnames(`specification ${className}`, {
        'with-white-icon': withWhiteIcon
      })}
    >
      <img
        width={width}
        height={height}
        src={image(specification.icon)}
        alt={specification.text}
        title={`${specification.text} ${specification.value}`}
      />
      <div className="info">
        <span className="value">
          {specification.value}{' '}
          <span>{specification.name === 'age' ? 'años' : ''}</span>
        </span>
        <span className="text">{specification.text}</span>
      </div>
      <style jsx>{specificationStyle}</style>
    </div>
  )
}

const Specifications = ({ property, withWhiteIcon, className, config }) => {
  const _config = config || defaultConfig
  const specifications = Object.keys(_config).map(k => ({
    ..._config[k],
    name: k,
    value: property[k],
    icon: withWhiteIcon && _config[k].whiteIcon ? _config[k].whiteIcon : _config[k].icon,
  }))

  return (
    <section className={`specifications ${className}`}>
      {specifications.map((s, i) => (
        <Specification specification={s} key={i} className={className} withWhiteIcon={withWhiteIcon} />
      ))}
      <style jsx>{specificationsStyles}</style>
    </section>
  )
}
export default Specifications
