import { createSelector } from 'reselect'

import { srpUrl, buildLocationUrlFromLocationObject } from 'app/services/seo'
import { normalizeAsUrl } from 'services/string'
import { required } from 'services/validators'

export const sanitizeSRPFilters = filters => {
  const { bedrooms, minBedrooms, parkingSpaces, minParkingSpaces, bathrooms, minBathrooms } = filters

  return {
    ...filters,
    bedrooms: bedrooms || minBedrooms,
    parkingSpaces: parkingSpaces || minParkingSpaces,
    bathrooms: bathrooms || minBathrooms,
  }
} 

const addOnlyNeededLocationInfoByLevel = (location, level) => {
  const finalLocation = {}

  if (level === 3) {
    finalLocation.label = location[levels[3]]

    finalLocation[levels[1]] = location[levels[1]]
    finalLocation[levels[2]] = location[levels[2]]
    finalLocation[levels[3]] = location[levels[3]]
  } else if (level === 2) {
    finalLocation.label = location[levels[2]]

    finalLocation[levels[1]] = location[levels[1]]
    finalLocation[levels[2]] = location[levels[2]]
  } else if (level === 1) {
    finalLocation.label = location[levels[1]]

    finalLocation[levels[1]] = location[levels[1]]
  }

  return finalLocation
}

const formatLocationToLink = (currentParams, location, level) => {
  const { state, municipality, neighborhood, ...rest } = location
  const { propertyType, operationType } = currentParams

  const locations = {
    ...addOnlyNeededLocationInfoByLevel(location, level)
  }

  const locationUrl = buildLocationUrlFromLocationObject(locations)

  locations.href = srpUrl({
    propertyType,
    operationType,
    location: locationUrl
  })

  return {
    ...locations,
    ...rest,
    propertyType,
    operationType,
    locationUrl,
    level
  }
}

const levels = {
  1: 'state',
  2: 'municipality',
  3: 'neighborhood'
}

const neighborhoodShouldBeAdded = (neighborhoodLocation, currentFilters) => {
  const { propertyType, operationType } = currentFilters

  if (!propertyType && !operationType) {
    return true
  }

  const areThereAllFilters = propertyType && operationType
  const propertyTypeAreEqual = neighborhoodLocation.propertyType === propertyType
  const operationTypeAreEqual = neighborhoodLocation.operationType === operationType

  if (areThereAllFilters) {
    return propertyTypeAreEqual && operationTypeAreEqual
  }

  if (propertyType) {
    return propertyTypeAreEqual
  }

  if (operationType) {
    return operationTypeAreEqual
  }

  return false
}

export const countResults = link => {
  if (link.links) {
    const newLinks = link.links.map(link => countResults(link))

    return {
      ...link,
      links: newLinks,
      count: newLinks.reduce((acc, count) => acc + count.count || count, 0)
    }
  }

  return link
}

const shouldAddLink = (footerLink, filters) => {
  const keyFilters = Object.keys(filters).filter(filter =>
    required(filters[filter])
  )

  return keyFilters.every(filter => footerLink[filter] === filters[filter])
}

export const footerLinksSelector = (footerLinks, currentFilters = {}) => {
  const formatLocation = formatLocationToLink.bind(null, currentFilters)
  const { operationType, propertyType } = currentFilters

  let CACHE_LOCATIONS = {}

  const finalTree = []

  for (const footerLink of footerLinks) {
    if (!shouldAddLink(footerLink, { operationType, propertyType })) {
      continue
    }

    const { district, ...rest } = footerLink
    const location = {
      municipality: district,
      ...rest
    }

    const { state, municipality, neighborhood } = location

    if (CACHE_LOCATIONS[state]) {
      const stateFoundIndex = finalTree.findIndex(
        treeLocation => treeLocation.state === state
      )

      if (CACHE_LOCATIONS[state][municipality]) {
        if (neighborhoodShouldBeAdded(location, currentFilters)) {
          const stateLinks = finalTree[stateFoundIndex].links
          const municipalityFoundIndex = stateLinks.findIndex(
            treeLocation => treeLocation.municipality === municipality
          )

          const municipalityLinks = stateLinks[municipalityFoundIndex].links

          // Neighborhood exist yet
          const neighborhoodFoundIndex = municipalityLinks.findIndex(
            treeLocation => treeLocation.neighborhood === neighborhood
          )

          if (neighborhoodFoundIndex !== -1) {
            municipalityLinks[neighborhoodFoundIndex].count += location.count
          } else {
            municipalityLinks.push(formatLocation(location, 3))
          }
        }
      } else {
        CACHE_LOCATIONS[state][municipality] = {}
        CACHE_LOCATIONS[state][municipality][neighborhood] = {}

        const links = finalTree[stateFoundIndex].links || []
        const neighborhoodLinks = []

        if (neighborhoodShouldBeAdded(location, currentFilters)) {
          neighborhoodLinks.push(formatLocation(location, 3))
        }

        finalTree[stateFoundIndex] = {
          ...formatLocation(location, 1),

          links: [
            ...links,
            {
              ...formatLocation(location, 2),
              links: neighborhoodLinks
            }
          ]
        }
      }
    } else {
      const { newState, cache } = registerNewState(location, currentFilters)

      CACHE_LOCATIONS = {
        ...CACHE_LOCATIONS,
        ...cache
      }

      finalTree.push(newState)
    }
  }

  return finalTree
}

const registerNewState = (location, currentFilters) => {
  const formatLocation = formatLocationToLink.bind(null, currentFilters)

  const CACHE = {}

  const { state, municipality, neighborhood } = location

  CACHE[state] = {}
  CACHE[state][municipality] = {}
  CACHE[state][municipality][neighborhood] = {}

  const neighborhoodLinks = []

  if (neighborhoodShouldBeAdded(location, currentFilters)) {
    neighborhoodLinks.push(formatLocation(location, 3))
  }

  const finalLocation = {
    ...formatLocation(location, 1),
    links: [
      {
        ...formatLocation(location, 2),
        links: neighborhoodLinks
      }
    ]
  }

  return { newState: finalLocation, cache: CACHE }
}

export const buildFooterLinks = (footerLinks, filters) =>
  footerLinksSelector(footerLinks, filters)
    .filter(({ state }) =>
      filters.selectedState
        ? normalizeAsUrl(state) === filters.selectedState
        : true
    )
    .map(link => countResults(link))

export const getFooterLinks = createSelector(
  state => state.srp.footerLinks,
  state => {
    const { selectedLocations, ...restFilters } = state.srp.filters
    const [selectedState] =
      (selectedLocations &&
        selectedLocations[0] &&
        selectedLocations[0].split('::').reverse()) ||
      []

    return { ...restFilters, selectedState }
  },
  buildFooterLinks
)

export const buildFooterLinksByCustomParams = customParams =>
  createSelector(
    state => state.srp.footerLinks,
    footerLinks => buildFooterLinks(footerLinks, customParams)
  )