import React from 'react'
import Head from 'next/head'

const JsonLD = props => (
  <Head>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(props.data, null, 0)
      }}
    />
  </Head>
)

export default JsonLD
