import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useSrpParams } from 'app/hooks/useParams'

import utils from 'services/utils'
import {
  getFooterLinks,
  buildFooterLinksByCustomParams
} from 'app/selectors/srp'
import { operationsSrpTitle } from 'services/seo'

import FooterLinkAccordion from 'components/Footer/NavigationPropertyFooter/FooterLinkAccordion'
import FooterFilters from 'components/Footer/FooterFilters'

import { getLinksTitle } from './utils'

import { statesFooterLinksStyles } from './styles'

const Title = ({ children }) => <h3>{children}</h3>

const LocationTitle = () => {
  const params = useSrpParams()
  const location = utils.splitUrlByLocation(params.location)

  return <Title>{getLinksTitle(location)}</Title>
}

const OperationTypeTitle = ({ operationType }) => (
  <Title>{operationsSrpTitle({ operationType })}</Title>
)

const Links = ({ links, levelsToShow = 1 }) => (
  <div className="links">
    {utils.makeChunks(links, 8).map((links, index) => (
      <FooterLinkAccordion
        forceShowMore
        key={index}
        links={links}
        levelsToShow={levelsToShow}
      />
    ))}

    {links.length === 0 && <p>Sin propiedades</p>}

    <style jsx>{statesFooterLinksStyles}</style>
  </div>
)

const FooterLinksByUrlparams = ({ levelsToShow }) => {
  const footerLinks = useSelector(getFooterLinks)

  return (
    <Fragment>
      <LocationTitle />

      <Links links={footerLinks} levelsToShow={levelsToShow} />
    </Fragment>
  )
}

const LinksByOperationType = ({ operationType }) => {
  const links = useSelector(buildFooterLinksByCustomParams({ operationType }))

  return (
    <Fragment>
      <OperationTypeTitle operationType={operationType} />
      <Links links={links} />
    </Fragment>
  )
}

const FooterLinksByOperationTypes = () => (
  <Fragment>
    <LinksByOperationType operationType="SELL" />

    <LinksByOperationType operationType="RENT" />
  </Fragment>
)

const StatesFooterLinks = ({ isIndexPage, levelsToShow }) => {
  return (
    <div className="state-footer-links-container">
      <h2>Otras formas de navegar</h2>

      <FooterFilters />

      {isIndexPage ? (
        <FooterLinksByOperationTypes />
      ) : (
        <FooterLinksByUrlparams levelsToShow={levelsToShow} />
      )}

      <style jsx>{statesFooterLinksStyles}</style>
    </div>
  )
}

export default StatesFooterLinks
