import css from 'styled-jsx/css'
import { colors } from 'theme'

export default css`
  .title {
    color: ${colors.white};
    margin: 0 0 1rem;
  }

  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
    padding: 0.3rem 0;
  }

  .links {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
  }

  .links.bottom-divider {
    border-bottom: 1px dashed;
    padding-bottom: 2.5rem;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 992px) {
    .title,
    .links {
      text-align: center;
    }
    .links {
      grid-row-gap: 0;
      grid-template-columns: 1fr;
    }

    li {
      padding: 0.5rem 0;
    }
  }
`
