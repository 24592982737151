import React from 'react'
import SeoPaginate from './SeoPaginate'
import { useSelector } from 'react-redux'
import { publicFilterPaginate } from 'selectors/publicFilterPaginate'
import { srpUrl } from 'services/seo'
import { SEARCH_CONFIG } from 'components/Srp'
import { getLocationUrl } from 'services/seo'
import { BASE_SRP_URL } from 'pagesConfig/utils/constants'

export default function PropertyPublicPaginate(props) {
  const filters = useSelector(state => state.srp.filters)
  const params = useSelector(publicFilterPaginate)
  const paramsLocation = getLocationUrl(filters)
  

  const urlWithParams = srpUrl({ ...filters, ...paramsLocation })
  const pathName =
    urlWithParams === '/' ? BASE_SRP_URL : urlWithParams
  const selected = filters.offset ? filters.offset / SEARCH_CONFIG.limit : 0

  return (
    <SeoPaginate
      {...props}
      selected={selected}
      queryParams={{ ...params.queryParams, ...paramsLocation }}
      urlParams={params.urlParams}
      pathName={pathName}
      urlPage="/srp"
    />
  )
}
