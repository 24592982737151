import classNames from 'classnames'

import { propertyFiltersStyles, controlFilterStyles } from './styles'

export function SectionControlFilter({ title, children, className }) {
  return (
    <div className={classNames('control-filter', className)}>
      {Boolean(title) && <p>{title}</p>}

      {children}

      <style jsx>{controlFilterStyles}</style>
    </div>
  )
}

function PropertyFiltersContainer({ children, className }) {
  return (
    <section className={classNames('property-filters', className)}>
      {children}
      <style jsx>{propertyFiltersStyles}</style>
    </section>
  )
}

export default PropertyFiltersContainer
