import React, { Component } from 'react'
import Router from 'next/router'
import { Input, Button, IonIcon } from 'controls'

import Breadcrumb from './getBreadcrumb'

import { headerSearchStyles } from './styles'

class HeaderSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: ''
    }
  }

  makeSearch = () => {
    Router.push(
      {
        pathname: 'srp',
        query: {
          search: this.state.search
        }
      },
      {
        pathname: '/search',
        query: {
          search: this.state.search
        }
      }
    )
    this.setState({ search: '' })
  }

  handleUpdateFilters = e => {
    this.setState({ search: e.target.value })
  }

  render() {
    const { filters, handleShowOptions } = this.props

    return (
      <div className="header-search-wrapper">
        <div className="header-search-content">
          <div className="header-search-breadcrumb">
            <Breadcrumb filters={filters} />
          </div>

          <div className="header-search-input">
            {handleShowOptions && (
              <Button
                color="primary"
                size="tiny"
                onClick={handleShowOptions}
                style={{ marginRight: '0.5rem' }}
                className="show-options-button"
              >
                <IonIcon icon="md-options" />
              </Button>
            )}
            <Input
              name="search"
              placeholder="Buscar..."
              className="input"
              onChange={this.handleUpdateFilters}
              value={this.state.search}
              aria-label="Buscar"
              onKeyUp={e => {
                if (e.keyCode === 13) {
                  this.makeSearch()
                }
              }}
            />
            <Button
              size="tiny"
              className="header-search-button"
              onClick={this.makeSearch}
              aria-label="Hacer busqueda"
            >
              <IonIcon icon="md-search" />
            </Button>
          </div>
        </div>
        <style jsx>{headerSearchStyles}</style>
      </div>
    )
  }
}

export default HeaderSearch
