import { END } from 'redux-saga'

import { wrapper } from 'app/store'
import { SeoUrlsActionCreators } from 'app/reducers/SeoUrlsRedux'

export const getSeoUrlsByPage = page =>
  wrapper.getServerSideProps(async context => {
    const { store, req } = context
    const asPath = req.url
    await store.dispatch(SeoUrlsActionCreators.all(page))
    await store.dispatch(END)
    await store.sagaTask.toPromise()

    return {
      props: {
        asPath
      }
    }
  })
