import { srpUrl } from 'services/seo'
import { normalizeAsUrl } from 'services/string'
import { removeAllEmptyKeys } from 'services/utils'

const getSrpLinkConfig = ({ operationType, propertyType, location, ...rest }) => {
  const formatedLocation = location && normalizeAsUrl(location)
  const params = removeAllEmptyKeys({
    operationType,
    propertyType,
    location: formatedLocation,
    ...rest
  })

  const srpUrlPath = srpUrl({
    operationType,
    propertyType,
    location: formatedLocation,
    ...rest
  })

  const srpLinkConfig = {
    href: {
      pathname: '/srp',
      query: params
    },
    as: {
      pathname: srpUrlPath,
      query: {}
    }
  }

  return srpLinkConfig
}

export default getSrpLinkConfig
