import React from 'react'
import { SimpleDropDown } from 'controls/DropDown'
import { PRICE_AMOUNT_RANGES } from 'services/seo'

import { currency as formatCurrency } from 'services/number'

const PRICE_OPTIONS = PRICE_AMOUNT_RANGES.map(amount => ({
  label: formatCurrency(amount),
  value: amount
}))

const MIN_AMOUNT = PRICE_AMOUNT_RANGES[0]
const MAX_AMOUNT = PRICE_AMOUNT_RANGES[PRICE_AMOUNT_RANGES.length - 1]

const PriceDropDown = ({ options = PRICE_OPTIONS, ...rest }) => (
  <SimpleDropDown {...rest}>
    {options.map(({ label, value }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </SimpleDropDown>
)

export const MinMaxPriceDropDown = ({
  minPrice,
  maxPrice,
  minFieldName = 'minPrice',
  maxFieldName = 'maxPrice',
  onChange = () => {},
}) => (
  <div className="price-dropdown-wrapper">
    <PriceDropDown
      forceEmptyOptionAsPlaceholder
      label="Precio mínimo"
      value={minPrice}
      name={minFieldName}
      options={PRICE_OPTIONS.filter(
        ({ value }) => value <= maxPrice || MAX_AMOUNT
      )}
      onChange={({ target }) =>
        onChange({ [minFieldName]: Number(target.value) })
      }
    />
    <PriceDropDown
      forceEmptyOptionAsPlaceholder
      label="Precio máximo"
      value={maxPrice}
      name={maxFieldName}
      options={PRICE_OPTIONS.filter(
        ({ value }) => value >= minPrice || MIN_AMOUNT
      )}
      onChange={({ target }) =>
        onChange({ [maxFieldName]: Number(target.value) })
      }
    />
  </div>
)

export default PriceDropDown
