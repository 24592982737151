import { colors, fonts, fontSizes, measurements } from 'theme'
import css from 'styled-jsx/css'

export default css.global`
  .nex-paginate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    list-style: none;
  }

  .nex-paginate-container a {
    color: ${colors.text};
    font-size: ${fontSizes.h4};
    padding: 0.3rem 1.8rem;
    cursor: pointer;
  }
  .nex-paginate-page a,
  .nex-paginate-next a,
  .nex-paginate-previous a {
    outline: unset;
  }

  .nex-paginate-page:hover a,
  .nex-paginate-next:hover a,
  .nex-paginate-previous:hover a {
    color: ${colors.primary};
  }

  .nex-paginate-page-active {
    border-radius: 0.4rem;
    background-color: ${colors.primary};
  }

  .nex-paginate-page-active a {
    color: ${colors.white};
  }
  .nex-paginate-page-active:hover a {
    color: ${colors.white};
  }
  .nex-paginate-next,
  .nex-paginate-previous {
    color: ${colors.primary};
  }

  @media screen and (max-width: 992px) {
    .nex-paginate-container a {
      padding: 0.8rem;
      font-size: ${fontSizes.body};
    }
  }
`
