import React, { Component } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styles from './styles'
import PageView from './PageView'
import BreakView from './BreakView'

import { calculateAsPathName } from './utils'

export default class SeoPaginate extends Component {
  handlePageSelected = selected => {
    const { selected: currentSelected } = this.props

    if (currentSelected === selected) {
      return
    }

    this.props.onPageChange(selected)
  }

  handlePreviousPage = evt => {
    const { selected } = this.props
    if (selected > 0) {
      this.props.onPageChange(selected - 1)
    }
  }

  handleNextPage = evt => {
    const { selected, pageCount } = this.props

    if (selected < pageCount - 1) {
      this.props.onPageChange(selected + 1)
    }
  }

  getPageElement = index => {
    const {
      selected,
      urlPage,
      queryParams,
      urlParams,
      pathName,
      nameLabel
    } = this.props
    return (
      <PageView
        key={index}
        urlPage={urlPage}
        queryParams={queryParams}
        urlParams={urlParams}
        pathName={pathName}
        nameLabel={nameLabel}
        selected={selected === index}
        page={index + 1}
      />
    )
  }

  pagination = () => {
    const items = []
    const {
      pageRangeDisplayed,
      pageCount,
      marginPagesDisplayed,
      breakLabel,
      breakClassName,
      selected
    } = this.props

    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        items.push(this.getPageElement(index))
      }
    } else {
      let leftSide = pageRangeDisplayed / 2
      let rightSide = pageRangeDisplayed - leftSide

      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected
        leftSide = pageRangeDisplayed - rightSide
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected
        rightSide = pageRangeDisplayed - leftSide
      }

      let index
      let page
      let breakView
      let createPageView = index => this.getPageElement(index)

      for (index = 0; index < pageCount; index++) {
        page = index + 1

        if (page <= marginPagesDisplayed) {
          items.push(createPageView(index))
          continue
        }

        if (page > pageCount - marginPagesDisplayed) {
          items.push(createPageView(index))
          continue
        }

        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(createPageView(index))
          continue
        }

        if (breakLabel && items[items.length - 1] !== breakView) {
          breakView = (
            <BreakView // Son los 3 puntitos
              key={index}
              breakLabel={breakLabel}
              breakClassName={breakClassName}
            />
          )
          items.push(breakView)
        }
      }
    }
    return items
  }

  render() {
    const {
      pageCount,
      previousLinkClassName,
      previousLabel,
      nextLinkClassName,
      nextLabel,
      selected,
      nameLabel,
      queryParams,
      urlParams,
      pathName,
      urlPage
    } = this.props

    const previousClasses = selected === 0 ? ` ${'disabled'}` : ''
    const nextClasses = selected === pageCount - 1 ? ` ${'disabled'}` : ''
    const offsetNextPage = selected + 2

    if (!pageCount) {
      return null
    }
    return (
      <ul className={'nex-paginate-container'}>
        <li className={previousClasses}>
          <Link
            href={{
              pathname: urlPage,
              query: { ...queryParams, page: selected }
            }}
            as={{
              pathname: calculateAsPathName({ pathName, nameLabel, page: selected }),
              query: { ...urlParams }
            }}
          >
            <a className={previousLinkClassName} tabIndex="0" role="button">
              {previousLabel}
            </a>
          </Link>
        </li>

        {this.pagination()}

        <li className={nextClasses}>
          <Link
            href={{
              pathname: urlPage,
              query: { ...queryParams, page: offsetNextPage }
            }}
            as={{
              pathname: calculateAsPathName({ pathName, nameLabel, page: offsetNextPage }),
              query: { ...urlParams }
            }}
          >
            <a className={nextLinkClassName} tabIndex="0" role="button">
              {nextLabel}
            </a>
          </Link>
        </li>
        <style jsx>{styles}</style>
      </ul>
    )
  }
}

SeoPaginate.defaultProps = {
  selected: 0, // CURRENT PAGE - Es el index del paginador, empezando en cero.
  pageCount: 10,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 2,
  previousLabel: 'Anterior',
  nextLabel: 'Siguiente',
  nameLabel: 'pagina',
  breakLabel: '...',
  queryParams: {}, // From getServerSideProps at context.query
  urlParams: {},
  // Same as queryParams but without params that are used at URL
  // Example: at "/some-page/:someUrlParam" -> "someUrlParam" should be removed from queryParams
  pathName: '',
  urlPage: ''
}

SeoPaginate.propTypes = {
  selected: PropTypes.number.isRequired,
  pageCount: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  previousLabel: PropTypes.any,
  nextLabel: PropTypes.any,
  breakLabel: PropTypes.any,
  nameLabel: PropTypes.any,
  queryParams: PropTypes.object,
  urlParams: PropTypes.object,
  pathName: PropTypes.string,
  urlPage: PropTypes.string
}
