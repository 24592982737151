import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useResizeWindow } from 'app/hooks'

import { IonIcon } from 'controls'

import calculateOffset from './calculateOffset'

import { carouselStyles } from './styles'

const SimpleCarousel = ({
  offset,
  className,
  buttonsColor,
  buttonsPosition,
  children
}) => {
  const windowSize = useResizeWindow()

  const refCarousel = React.useRef(null)
  const refCarouselContainer = React.useRef(null)
  const [translate, setTranslate] = React.useState(0)

  useEffect(
    () => {
      translateCarousel(0)
    },
    [windowSize]
  )

  const translateCarousel = newPosition => {
    refCarousel.current.style.transform = `translateX(${newPosition}px)`
    setTranslate(newPosition)
  }

  const handleMove = (offset = 0) => {
    const maxOffet = calculateOffset(
      refCarouselContainer.current,
      refCarousel.current,
      offset,
      translate
    )

    translateCarousel(maxOffet)
  }

  return (
    <div
      className={classnames(
        'carousel-container-buttons',
        'app-wrapper-container',
        buttonsPosition,
        className
      )}
    >
      <button
        className={classnames('left-button', buttonsColor)}
        onClick={() => handleMove(-offset)}
        aria-label="Recorrer a la izquierda"
      >
        <div className="circle">
          <IonIcon icon="md-arrow-back" size="small" />
        </div>
      </button>
      <div className="carousel-container" ref={refCarouselContainer}>
        <div className="carousel app-wrapper-container" ref={refCarousel}>
          {children}
        </div>
      </div>
      <button
        className={classnames('right-button', buttonsColor)}
        onClick={() => handleMove(offset)}
        aria-label="Recorrer a la derecha"
      >
        <div className="circle">
          <IonIcon icon="md-arrow-forward" size="small" />
        </div>
      </button>

      <style jsx>{carouselStyles}</style>
    </div>
  )
}

SimpleCarousel.defaultProps = {
  offset: 200,
  buttonsColor: 'white',
  buttonsPosition: 'outside'
}

SimpleCarousel.propTypes = {
  offset: PropTypes.number, // num of pixels that be scrolled in each click 
  buttonsColor: PropTypes.oneOf(['white', 'secondary']),
  buttonsPosition: PropTypes.oneOf(['outside', 'overElemens'])
}

export default SimpleCarousel
