import { fontSizes, getFont, measurements, colors, fontWeights } from 'app/theme'
import css from 'styled-jsx/css'

export default css`
  .vip .vip-content {
    display: grid;
    grid-gap: 1rem;
    position: relative;
    grid-template-areas: "header        header      "
                         "gallery       gallery     "
                         "vipInfo  vipSidebarContent";
    grid-template-columns: 1fr auto;
  }

  .vip .vip-content.only-one-image-gallery {
    grid-template-areas: "header        header      "
                         "gallery  vipSidebarContent"
                         "vipInfo  vipSidebarContent";
  }

  .vip .vip-content.without-side-bar {
    grid-template-areas: "header"
                         "gallery"
                         "vipInfo";
  }

  .vip .vip-content :global(.vip-header) {
    grid-area: header;
  }
  .vip .vip-content :global(.vip-gallery-container) {
    grid-area: gallery;
  }
  .vip .vip-content .vip-info {
    grid-area: vipInfo;
  }
  .vip .vip-content .vip-sidebar-content {
    grid-area: vipSidebarContent;
  }


  .vip,
  .vip-info {
    display: flex;
    flex-direction: column;
  }

  .vip {
    width: 100%;
    max-width: ${measurements.containerSize};
    margin-left: auto;
    margin-right: auto;
  }

  .vip-info {
    width: 100%;
    max-width: 46rem;
  }
  .vip-info.full {
    max-width: initial;
  }

  .vip-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .vip .vip-sidebar-content {
    position: sticky;
    top: 5.375rem;

    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 22rem;
    width: 100%;
    padding-left: 1rem;
  }

  .vip .vip-sidebar-content > :global(*) {
    position: initial;
    margin-top: 0;
    margin-bottom: 0.5rem;
    height: fit-content;
    max-width: 22rem;
    border-radius: ${measurements.borderRadius};
  }

  .vip-content.suggested-links :global(.vip-navigation-footer-suggested-links) {
    margin-left: 0;
    margin-right: 0;
  }

  .hidden-placeholder-element {
    border-bottom: 1px solid ${colors.border};
  }

  .vip-breadcrumb-header {
    width: 100%;
    padding: 0.5rem;
    background-color: ${colors.white};
    margin-bottom: 1rem;
  }
  .vip-breadcrumb-header .container {
    width: 100%;
    max-width: ${measurements.containerSize};
    margin: 0 auto;
  }
  .title-deleted-at {
    margin-bottom: 0;
    font-weight: 600;
  }
  .subtitle-deleted-at {
    margin-top: 1rem;
    font-weight: 600;
  }
  .img-not-property {
    width: 100%;
  }
  .view-property-text {
    color: ${colors.primary};
    font-size: 1.2rem;
    cursor: pointer;
  }

  .vip-info :global(.panel:first-child) {
    margin-top: 0;
  }

  @media screen and (max-width: 992px) {
    .propertyDeteledAt {
      margin-top: 5rem;
    }
    .vip {
      margin-bottom: 2rem;
    }
    .vip-content {
      flex-direction: column;
      margin: 0 auto;
    }
    .vip .vip-sidebar-content {
      padding-left: initial;
    }
    .vip .vip-content,
    .vip .vip-sidebar-content {
      max-width: 46rem;
      width: 100%;
    }
    .vip .vip-sidebar-content > :global(*) {
      margin-top: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 52rem;
    }

    .vip .vip-content {
      display: flex;
      grid-gap: 0;
    }
  }
`

export const header = css`
  header {
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.border};
    position: relative;
    display: flex;
    z-index: 5;
    justify-content: space-between;
    padding: 0.5rem 3rem;
  }

  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  header.fixed {
    border-top: 1px solid transparent;
  }
  @media screen and (max-width: 992px) {
    .fixed {
      margin-top: 3.7rem;
    }
  }

  .left-info,
  .right-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .left-info > *,
  .right-info > * {
    margin: 0;
  }

  .left-info {
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .right-info {
    justify-content: space-evenly;
    align-items: flex-end;
  }

  header :global(.back-icon) {
    position: absolute;
    top: 40%;
    left: 1rem;
    cursor: pointer;
  }

  .title {
    ${getFont('semiBold', 'body')};
  }

  .price {
    ${getFont('semiBold', 'h4')};
    color: ${colors.primary};
  }

  .price .currency {
    font-size: ${fontSizes.small};
  }

  .address {
    ${getFont('semiBold', 'tiny')};
  }

  .header.nexsite1-header {
    margin-top: 0;
    padding: 0.9375rem 1.25rem;
    z-index: 11;
  }

  .header.nexsite1-header h1 {
    font-size: 1.125rem;
  }

  .header.nexsite1-header h4.address {
    color: ${colors.lightText};
    font-size: 0.875rem;
    font-weight: normal;
  }
`

export const simpleHeader = css`
  .vip-simple-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .title,
  .address,
  .price {
    margin: 0;
  }

  .title {
    margin-bottom: 0.5rem;
    font-weight: ${fontWeights.semiBold};
  }

  .address,
  .price {
    font-size: ${fontSizes.h4};
  }

  .titles-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .divider {
    padding: 0 1rem 0.5rem;
  }

  @media screen and (max-width: 992px) {
    .title {
      text-align: center;
      font-size: ${fontSizes.h3};
    }
    .titles-container {
      flex-direction: column;
      align-items: center;
    }

    .price,
    .address {
      margin: 0.5rem 0;
    }

    .divider {
      display: none;
    }
  }
`

export const gallery = css`
  .container {
    position: relative;
  }

  .actions {
    display: flex;
    list-style: none;
    position: absolute;
    z-index: 2;
    top: 1rem;
    left: 1rem;
    padding: 0;
    margin: 0;
  }
  .actions > li {
    margin-right: 0.625rem;
    cursor: pointer;
  }

  .actions.nexsite1-slide {
    display: none;
  }
`

export const panelTitles = css`
  .panel-title {
    margin: 0;
    font-size: ${fontSizes.h5}
  }
`

export const iconNav = css`
  .background {
    cursor: pointer;
    border-radius: 50%;
    background-color: ${colors.white};
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .background :global(i) {
    font-size: ${fontSizes.body};
  }
`

export const panel = css`
  .panel {
    border-radius: ${measurements.borderRadius};
    background-color: ${colors.white};
    border: solid 1px ${colors.border};
    padding: 1.5rem 2rem;
    margin-top: 1rem;
    word-break: break-word;
  }
  .panel > header {
  }

  .panel.nexsite1-panel {
    margin-top: 1.25rem;
    box-shadow: 0 0.5rem 1.25rem 0 rgba(197, 207, 222, 0.5);
  }

  .panel.nexsite1-panel header {
    font-weight: 600;
  }

  .panel.nexsite1-panel.title-space header {
    margin-bottom: 1.5625rem;
  }

  .panel.nexsite1-panel.not-margin {
    margin: 0;
  }
`

export const specifications = css`
  .specifications {
    flex-wrap: wrap;
    display: flex;
    padding: 0;
    justify-content: space-between;
    align-items: center;
  }
  .specifications.inline {
    min-width: 4rem;
    display: flex;
    justify-content: center;
  }
  .specifications.inline.small {
    min-width: 2rem;
    width: 100%;
    justify-content: flex-start;
  }
`

export const specification = css`
  .specification {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    padding: 0 1rem;
    min-width: auto;
  }
  .specification.inline {
    width: auto;
    margin: 1rem;
    margin: 0.5rem;
    padding: 0;
    width: 6rem;
    min-width: inherit;
    display: block;
  }
  .specification.small {
    margin-top: 0.5rem;
    padding: 0;
    width: auto;
    min-width: 2.5rem;
  }
  .specification.inline.small {
    display: flex;
    flex-direction: row;
    margin: 0;
  }
  .specification img {
    height: 2.25rem;
    width: auto;
  }
  .specification.small img {
    height: 1.3rem;
  }

  .specification.inline img {
    margin-right: 0rem;
  }

  .specification .value,
  .specification .text {
    margin: 0;
    width: auto;
  }

  .value {
    font-size: ${fontSizes.h4};
    color: ${colors.primary};
  }
  .specification.small .value {
    font-size: ${fontSizes.p};
  }
  .specification.small .text {
    display: none;
  }

  .value span {
    font-size: ${fontSizes.small};
  }

  .text {
    font-size: ${fontSizes.tiny};
    text-align: center;
    width: min-content;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    flex: 1;
    width: 100%;
    align-items: center;
  }

  .with-white-icon .info .text,
  .with-white-icon .info .value {
    color: ${colors.white};
  }

  @media screen and (max-width: 1024px) {
    .specification.inline {
      margin: 1rem 0.5rem;
    }
  }

  .specification.nexsite1-specs {
    min-width: 12.5rem;
    flex-direction: row;
    margin-top: 1.875rem;
  }

  .specification.nexsite1-specs .info {
    align-items: flex-start;
    margin-left: 0.9375rem;
  }
`

export const amenities = css`
  .amenities {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
  }

  .amenities :global(.see-more-button) {
    margin-top: 2rem;
    color: ${colors.primary};
  }

  .amenities :global(.see-more-button i) {
    margin-left: 1rem;
  }
`

export const amenity = css`
  .amenity {
    font-size: ${fontSizes.small};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2.5rem;
    margin-right: 1rem;
    min-width: 14rem;
  }

  .amenity > :global(i) {
    margin-right: 1rem;
    color: ${colors.primary};
  }

  .amenity.nexsite1-amenities {
    min-width: auto;
    width: 11.875rem;
  }
`

export const description = css`
  .description {
    font-size: ${fontSizes.small};
    word-break: break-word;
    text-align: justify;
  }
  .content :global(a){
    color: ${colors.text};
    text-decoration: underline;
  }
  .property-number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
  }
`

export const video = css`
  .video {
    position: relative;
    padding-top: 56.25%;
  }
  .video :global(.react-player) {
    position: absolute;
    top: 0;
    left: 0;
  }
`
export const virtualTour = css`
  .virtual-tour {
    padding-top: 1rem;
  }
`

export const tagOcupationStatusStyles = css`
  :global(.label.small.tag-ocupation-status-labe-wrapper) {
    position: absolute;
    z-index: 1;
    border-radius: 0;
    padding-left: 0;
  }
  .label {
    margin: 0.5rem;
  }

  :global(.label.small.tag-ocupation-status-labe-wrapper.position-top-left .label),
  :global(.label.small.tag-ocupation-status-labe-wrapper.position-bottom-left .label) {
    margin: 0.5rem 1.2rem 0.5rem 1rem;
  }
  :global(.label.small.tag-ocupation-status-labe-wrapper.position-top-right .label),
  :global(.label.small.tag-ocupation-status-labe-wrapper.position-bottom-right .label) {
    margin-left: 1.5rem;
  }

  :global(.label.small.tag-ocupation-status-labe-wrapper.position-top-left) {
    top: 1rem;
    left: 0;
    clip-path: polygon(100% 0, 90% 49%, 100% 100%, 0% 100%, 0 52%, 0% 0%);
  }

  :global(.label.small.tag-ocupation-status-labe-wrapper.position-top-right) {
    top: 1rem;
    right: 0;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0.6rem 50%, 0% 0%);
  }

  :global(.label.small.tag-ocupation-status-labe-wrapper.position-bottom-left) {
    bottom: 1rem;
    left: 0;
    clip-path: polygon(100% 0, 90% 49%, 100% 100%, 0% 100%, 0 52%, 0% 0%);
  }

  :global(.label.small.tag-ocupation-status-labe-wrapper.position-bottom-right) {
    bottom: 1rem;
    right: 0;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0.6rem 50%, 0% 0%);
  }
`

export const moreDetailPropertyStyles = css`
  .more-details-property-container {
    position: relative;
    margin: 0;
    width: 100%;
    max-width: ${measurements.containerSize};
  }
  .more-details-property-container.short:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      ${colors.alpha(colors.lightBg, 0.5)},
      ${colors.alpha(colors.lightBg, 1)} 90%,
      ${colors.lightBg}
    );
  }
  p {
    line-height: 2rem;
    text-align: left;
  }
  h4 {
    margin: 0;
  }
`

export const vipContainer = css`
  .vip-container {
    flex: 1;
    margin-top: ${measurements.headerHeight};
  }
  .vip-content {
    max-width: ${measurements.containerSize};
    margin-left: auto;
    margin-right: auto;
  }
  .suggested-links {
    margin-bottom: 1.5rem;
  }
`

export const suggestedPropertiesCarouselStyles = css`
  .suggested-properties-carousel {
    width: 100%;
  }

  .title {
    font-weight: ${fontWeights.semiBold};
  }

  @media screen and (max-width: 992px) {
    .title {
      padding: 0 1rem;
    }
  }
`

export const claimedVipStyles = css`
  .breadcrumb {
    padding: 0.5rem 0;
  }
  .suggested-properties-carousel {
    background-color: ${colors.dullGray};
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 992px) {
    .breadcrumb {
      margin-top: 4rem;
    }
  }
`

export const seoGallery = css`
  .vip-gallery-container {
    position: relative;
  }
  .vip-gallery-container :global(.gallery-actions) {
    position: absolute;
    top: initial;
    bottom: 1.5rem;
  }
  .hide-vip-gallery {
    display: none;
  }

  :global(button.button.vip-gallery-show-full-screen-button) {
    display: none;
  }

  @media screen and (max-width: 992px) {
    :global(button.button.vip-gallery-show-full-screen-button) {
      display: initial;
      margin-bottom: 1rem;
      width: fit-content;
    }

    .vip-gallery-container :global(.gallery-actions) {
      bottom: 5.5rem;
    }
  }
`

export const gridGalleryStyles = css`
  .vip-grid-gallery {
    display: grid;
    grid-template-rows: 50% 50%;
    height: 25rem;
    grid-gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .vip-grid-gallery :global(.vip-grid-gallery-image) {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${measurements.borderRadius};
    cursor: pointer;
  }

  .vip-grid-gallery :global(picture:first-child .vip-grid-gallery-image ) {
    object-fit: contain;
  }

  .vip-grid-gallery :global(.blured-image) {
    filter: blur(2px);
  }

  .vip-grid-gallery :global(picture) {
    transition: all 0.3s;
  }

  .vip-grid-gallery :global(picture:last-child) {
    position: relative;
  }

  .vip-grid-gallery :global(picture:last-child:after) {
    content: "Ver fotos";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.alpha(colors.black, 0.5)};
    color: ${colors.white};
    border-radius: ${measurements.borderRadius};
    font-size: ${fontSizes.h5};
    cursor: pointer;
    transition: all 0.3s;
  }
  .vip-grid-gallery :global(picture:last-child:hover:after) {
    content: "";
    top: 100%;
    background-color: ${colors.alpha(colors.black, 0.5)};
  }

  .vip-grid-gallery :global(picture:first-child) {
    grid-row: 1 / 3;
  }
  .vip-grid-gallery :global(picture:first-child:after) {
    content: "";
    top: 100%;
  }
  .full-grid-gallery {
    grid-template-columns: 1fr 20% 20%;
  }
  .middle-grid-gallery{
    grid-template-columns: 1fr 30%;
  }
  .simple-grid-gallery{
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 992px) {
    .vip-grid-gallery {
      grid-gap: 0;
      height: auto;
      max-height: 30rem;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(auto-fill, 15.625rem);
    }
    .vip-grid-gallery :global(picture:first-child),
    .vip-grid-gallery :global(picture:first-child img) {
      display: initial;
      max-height: 30rem;
    }
    .vip-grid-gallery :global(picture) {
      display: none;
    }
  }
`