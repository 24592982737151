import { colors, measurements, fontSizes } from 'app/theme'
import css from 'styled-jsx/css'

import { SRP_CARD_WIDTH } from './config'

export default css`
  .wrapper-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: ${colors.tertiary};
  }

  .container {
    width: 100%;
    margin: 0 auto;
  }

  .container:nth-child(n + 2) {
    margin-top: 4rem;
  }

  .results {
    width: 100%;

    display: grid;
    grid-template-columns: 15rem 1fr;
  }

  .is-index-page.results {
    grid-template-columns: 1fr;
  }
  .results-count {
    color: ${colors.primary};
  }

  .results :global(.results-grid) {
    width: 100%;
  }
  .container-results-grid {
    flex: 1;
    width: 100%;
  }

  .container-results-grid .paginate {
    margin: 0 auto;
    max-width: 50rem;
  }

  .actions-filters.active {
    height: 1.5rem;
  }

  .srp-container .wrapper-bin-filters :global(.close-filters-button) {
    display: none;
  }

  .suggested-links {
    margin-top: 4rem;
  }

  @media screen and (max-width: 992px) {
    .container:nth-child(n + 2) {
      margin-top: 2.5rem;
    }
    .wrapper-container {
      padding-top: 1rem;
    }
    .wrapper-container.active {
      height: 0;
      overflow: hidden;
    }
    .wrapper-container .results {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    .srp-container .wrapper-bin-filters.active {
      z-index: 100;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      overflow-y: scroll;
      background-color: ${colors.tertiary};
    }

    .srp-container .wrapper-bin-filters.active :global(.close-filters-button) {
      display: initial;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 5;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }
    .srp-container
      .wrapper-bin-filters.active
      :global(.close-filters-button i) {
      margin: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .suggested-links {
      max-width: 21.875rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const breadcrumbResultsStyle = css`
  .breadcrumb-results-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }

  .breadcrumb-results .title {
    font-size: ${fontSizes.h5};
  }

  .breadcrumb-results .breadcrumb-results-count {
    font-size: ${fontSizes.h4};
    color: ${colors.primary};
    font-weight: bold;
  }

  .breadcrumb-results .loading-text {
    font-size: ${fontSizes.h4};
  }

  @media screen and (max-width: 772px) {
    .breadcrumb-results-container {
      flex-direction: column;
    }
    .breadcrumb-results .title {
      margin: 0 0 0.5rem 0;
    }
  }
`

export const filterControlsStyles = css`
  .srp-filter-controls-wrapper {
    background-color: ${colors.white};
    border-radius: ${measurements.borderRadius};
    border: 1px solid ${colors.primary};
    margin-top: 1rem;
    display: flex;
  }

  .srp-filter-controls-wrapper > :global(button.filter-control) {
    min-width: 5rem;
    border: none;
    border-radius: 0;
    flex: 1;
  }

  .srp-filter-controls-wrapper > :global(button.filter-control:nth-child(1)) {
    border-radius: ${measurements.borderRadius} 0 0 ${measurements.borderRadius};
  }
  .srp-filter-controls-wrapper > :global(button.filter-control:nth-child(2)) {
    border-radius: 0 ${measurements.borderRadius} ${measurements.borderRadius} 0;
  }
`

export const srpResultsContainer = css`
  .srp-results-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${SRP_CARD_WIDTH}, ${SRP_CARD_WIDTH}));
    grid-gap: 2rem 0.2rem;
    justify-content: space-evenly;
  }
  .srp-results-container.is-index-page {
    grid-template-columns: repeat(auto-fill, minmax(${SRP_CARD_WIDTH}, 22rem));
  }

  .srp-results-container .loading-srp-card {
    position: relative;
    pointer-events: none;
  }
  .srp-results-container .loading-srp-card :global(.srp-property-card) {
    filter: blur(3px);
  }

  .srp-results-container .loading-srp-card :global(.loader) {
    position: absolute;
    top: 25%;
    right: calc(50% - 25px);
    z-index: 10;
  }

  @media screen and (max-width: 548px) {
    .srp-results-container {
      grid-template-columns: repeat(auto-fill, minmax(15rem, 19rem));
    }
  }

  @media screen and (min-width: 1200px) {
    .srp-results-container :global(.srp-property-card) {
      margin-bottom: 2rem;
    }
    .srp-results-container.is-index-page :global(.srp-property-card) {
      margin-bottom: 2rem;
    }
  }
`
