import SearchResults from './SearchResults'
import HeaderSearch from './HeaderSearch'

export const SEARCH_CONFIG = {
  limit: 12
}

export default {
  SearchResults,
  HeaderSearch
}
