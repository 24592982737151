import { PROPERTY_TYPES, OPERATION_TYPES, srpUrl } from 'services/seo'
import { normalizeAsUrl, titleize } from 'services/string'

import { numeric } from 'app/services/validators'

const formatLabel = label => (label === 'Local' ? `${label}es` : `${label}s`)
const getValueOfObjet = obj => (obj || {}).value
const gV = getValueOfObjet

export const getRightUrl = (url = '') => {
  const [http, ...restUrl] = url.split('//')
  return `${http}//${restUrl.join('/')}`
}

const getFormatedParamsValues = (filters, countResults) => {
  const { operationType: opT, propertyType: pT, selectedLocations } = filters

  const isFormatResult = numeric(countResults)

  const breadcrumb = []

  const operationType = OPERATION_TYPES.find(({ value }) => value === opT)
  const propertyType = PROPERTY_TYPES.find(({ value }) => value === pT)

  if (operationType) {
    breadcrumb.push({
      key: 'operationType',
      ...operationType
    })
  }

  if (propertyType) {
    const { label } = propertyType
    breadcrumb.push({
      key: 'propertyType',
      ...propertyType,
      label: countResults > 1 ? formatLabel(label) : label
    })
  } else if (isFormatResult) {
    breadcrumb.push({
      key: 'propertyType',
      label: countResults === 1 ? 'Inmueble' : 'Inmuebles'
    })
  }

  if (selectedLocations && selectedLocations.length) {
    const locations = Array.isArray(selectedLocations)
      ? selectedLocations[0]
      : selectedLocations

    const [state, municipality, neighborhood] = locations.replace(/ /gi, '-').split('::').reverse()

    if (state) {
      breadcrumb.push({
        key: 'state',
        value: state,
        label: titleize(state.replace(/-/gi, ' '))
      })
    }

    if (municipality) {
      breadcrumb.push({
        key: 'municipality',
        value: `${municipality}--${state}`,
        label: titleize(municipality.replace(/-/gi, ' '))
      })
    }

    if (neighborhood) {
      breadcrumb.push({
        key: 'neighborhood',
        label: titleize(neighborhood.replace(/-/gi, ' ')),
        value: `${neighborhood}--${municipality}--${state}`
      })
    }
  }

  return breadcrumb.filter(({ label }) => label)
}

export const getBiblioOfParams = (params = []) =>
  params.reduce(
    (acc, { key, ...rest }) => ({ ...acc, [key]: { key, ...rest } }),
    {}
  )

export const formatParamsToString = params => {
  const {
    propertyType,
    operationType,
    neighborhood,
    municipality,
    state
  } = params

  let breadcrumb = ''

  if (propertyType) {
    breadcrumb = propertyType.label
  }

  if (operationType) {
    breadcrumb += ` en ${operationType.label}`
  }

  if (neighborhood && municipality && state) {
    breadcrumb += ` en ${state.label}, ${municipality.label}, ${
      neighborhood.label
    }`
  } else if (municipality && state) {
    breadcrumb += ` en ${state.label}, ${municipality.label}`
  } else if (state) {
    breadcrumb += ` en ${state.label}`
  }

  return breadcrumb
}

export const formatParamsToLinks = ({ formatedParams }) => {
  const {
    propertyType,
    operationType,
    neighborhood,
    municipality,
    state
  } = formatedParams

  let breadcrumb = []

  const sanitizedParams = {
    propertyType: gV(propertyType),
    operationType: gV(operationType),
    neighborhood: gV(neighborhood),
    municipality: gV(municipality),
    state: gV(state)
  }

  const prevUrl = srpUrl({
    operationType: sanitizedParams.operationType,
    propertyType: sanitizedParams.propertyType
  })

  const getAsPath = location =>
    location ? `${prevUrl}/${location}`.split('//').join('/') : prevUrl

  const getHrefQuery = location => ({
    location,
    operationType: sanitizedParams.operationType,
    propertyType: sanitizedParams.propertyType
  })

  const addNewRoute = ({ location, label } = {}) => {
    breadcrumb.push({ label, query: getHrefQuery(location), as: normalizeAsUrl(getAsPath(location)) })
  }

  if (propertyType) {
    const as = normalizeAsUrl(srpUrl({ propertyType: sanitizedParams.propertyType }))
    breadcrumb.push({ label: propertyType.label, query: { propertyType: sanitizedParams.propertyType }, as })
  }

  if (operationType) {
    addNewRoute({ label: operationType.label })
  }

  if (state) {
    addNewRoute({ label: state.label, location: (sanitizedParams.state) })
  }

  if (municipality && state) {
    addNewRoute({ label: municipality.label, location: (sanitizedParams.municipality) })
  }

  if (neighborhood && municipality && state) {
    addNewRoute({ label: neighborhood.label, location: (sanitizedParams.neighborhood) })
  }

  let linkPosition = 0

  breadcrumb = breadcrumb.map(data => {
    if (data.as) {
      linkPosition += 1
      return { ...data, linkPosition }
    }
    return data
  })

  return breadcrumb
}

export default getFormatedParamsValues
