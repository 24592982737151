import React from 'react'

import {
  OPERATION_TYPES,
  PROPERTY_TYPES,
} from 'services/seo'

import {
  OperationTypeLink,
  PropertyTypeLink,
  FiltersPanel
} from './FooterLinks'

import {
  footerFiltersStyles,
  operationTypesFiltersStyles,
  propertyTypesFiltersStyles
} from './styles'

const OperationTypesFilters = () => {
  return (
    <ul className="operation-types-filters" title="Filtrar por tipo de operación">
      {OPERATION_TYPES.map(({ url, label, value }) => (
        <li key={value}>
          <OperationTypeLink label={label} value={value}  />
        </li>
      ))}
      <style jsx>{operationTypesFiltersStyles}</style>
    </ul>
  )
}

const PropertyTypesFilters = () => (
  <ul className="property-types-filters" title="Filtrar por tipo de propiedad">
    {PROPERTY_TYPES.map(propertyTypeInfo => (
      <li key={propertyTypeInfo.value}>
        <PropertyTypeLink {...propertyTypeInfo} />
      </li>
    ))}
    <style jsx>{propertyTypesFiltersStyles}</style>
  </ul>
)

const FooterFilters = () => (
  <div className="footer-filters-container app-wrapper-container">
    <FiltersPanel>
      <OperationTypesFilters />
    </FiltersPanel>

    <FiltersPanel>
      <PropertyTypesFilters />
    </FiltersPanel>

    <style jsx>{footerFiltersStyles}</style>
  </div>
)

export default FooterFilters
