import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { IonIcon } from 'controls'

import { simpleAlertStyles } from './styles'

const SimpleAlert = ({ children, icon, type, textPosition, ...rest }) => (
  <div className={classnames('alert', type, textPosition)} {...rest}>
    {icon || <IonIcon icon="md-information-circle" className="alert-icon" />}
    {children}
    <style jsx>{simpleAlertStyles}</style>
  </div>
)

SimpleAlert.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  type: PropTypes.oneOf(['danger', 'purple', 'blue']),
  textPosition: PropTypes.oneOf(['left', 'rigth'])
}

export default SimpleAlert
