import React, { Fragment } from 'react'
import classnames from 'classnames'
import { breackViewStyle } from './styles'

const BreakView = ({ breakLabel, breakClassName }) => {
  return (
    <Fragment>
      <li className={classnames('break' || breakClassName)}>{breakLabel}</li>
      <style jsx>{breackViewStyle}</style>
    </Fragment>
  )
}

export default BreakView
