import React from 'react'
import ReactPaginate from 'react-paginate'

import { IonIcon } from 'controls'

import styles from './styles'

const classes = {
  breakClassName: 'nex-paginate-previous-break',
  containerClassName: 'nex-paginate-container',
  pageClassName: 'nex-paginate-page',
  pageLinkClassName: 'nex-paginate-link',
  activeClassName: 'nex-paginate-page-active',
  previousClassName: 'nex-paginate-previous',
  nextClassName: 'nex-paginate-next',
  previousLinkClassName: 'nex-paginate-previous-link',
  nextLinkClassName: 'nex-paginate-previous-link',
  disabledClassName: 'nex-paginate-disabled'
}

const Paginate = props =>
  props.pageCount > 0 ? (
    <div className={props.className || ''} style={{ width: '100%' }}>
      <ReactPaginate
        nextLabel="Siguiente"
        previousLabel="Anterior"
        breakLabel="..."
        disableInitialCallback
        {...classes}
        {...props}
      />
      <style jsx global>
        {styles}
      </style>
    </div>
  ) : null

export default Paginate