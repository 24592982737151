import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import config from 'config'

import { footerUrls } from 'app/selectors/seoUrls'
import { makeChunks } from 'services/utils'
import styles from './style'

const neximoUrl = config('HOST_URL')

const SeoFooter = ({ title = 'Te podría interesar', bottomDivider }) => {
  const urls = useSelector(footerUrls)

  if (urls.length === 0) {
    return null
  }

  return (
    <Fragment>
      <h4 className="title">{title}</h4>

      <div className={classnames("links", { 'bottom-divider': bottomDivider })}>
        {makeChunks(urls, 5).map((urls, index) => (
          <ul key={index}>
            {urls.map(({ id, label, url }) => (
              <li key={id}>
                <a href={neximoUrl + url} target="_blank">
                  {label}
                </a>
              </li>
            ))}
          </ul>
        ))}
      </div>

      <style jsx>{styles}</style>
    </Fragment>
  )
}

export default SeoFooter
