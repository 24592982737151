import React from 'react'

import {
  DROPDOWN_AMENITIES,
  AMENITIES_LABELS_BUILDERS
} from 'services/seo/amenities'

import { LabelWithRemove } from 'controls/Label'

const AmenitiesFilters = ({ filters, handleRemoveFilter, className }) =>
  DROPDOWN_AMENITIES.filter(({ value }) => filters[value]).map(({ value }) => {
    const labelBuilder = AMENITIES_LABELS_BUILDERS[value]

    return (
      <LabelWithRemove
        key={value}
        color="info"
        size="small"
        onRemove={handleRemoveFilter(value)}
        style={{ margin: '.2rem' }}
        className={className}
      >
        {labelBuilder(filters)}
      </LabelWithRemove>
    )
  })

export default AmenitiesFilters
