import React from 'react'

import { NavBarContainer } from './NavigationContainers'
import { OpenMenuButton } from './NavBarHamburgerMenu'

import {
  LinkLogoHome,
  LinkJoin,
  LinkSrp,
  LinkTeam,
  MagazineLink,
  AuthDashboardLink,
  AuthLink,
  ValuationLink,
  CountryLink
} from './NavigationLinks'

export const NavBarMenuDesktop = ({
  fixed,
  overFixed,
  whiteNavBar,
  negativeMenuProps,
  negativeMenuState,

  blueNavBar,

  isAuthenticated,
  hiddeValuationBtn,
  negativeMenu,
  handleShowNavBarMobileMenu
}) => {
  return (
    <NavBarContainer
      fixed={fixed}
      overFixed={overFixed}
      whiteNavBar={whiteNavBar}
      negativeMenuProps={negativeMenuProps}
      negativeMenuState={negativeMenuState}
      blueNavBar={blueNavBar}
    >
      <LinkLogoHome negativeMenu={negativeMenu} />
      <LinkJoin />
      <LinkSrp />
      <LinkTeam />
      <MagazineLink />

      <AuthDashboardLink isAuthenticated={isAuthenticated} />
      <AuthLink isAuthenticated={isAuthenticated} />
      <ValuationLink hiddeValuationBtn={hiddeValuationBtn} />
      <OpenMenuButton
        className="handle-show-nav-bar-mobile-menu-button"
        handleClick={handleShowNavBarMobileMenu}
      />
      <CountryLink />
    </NavBarContainer>
  )
}
