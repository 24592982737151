import React from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import Link from 'next/link'
import classnames from 'classnames'

import Property from 'classes/Property'
import { defaultConfig } from 'components/Vip/Specifications'
import TagOcupationStatus from 'components/Vip/TagOcupationStatus'

import { formatCurrency } from 'services/number'
import { getFirstImageOriginalProperty } from 'services/utils'
import { PictureCropped } from 'components/Picture'
import { Label } from 'controls'

import {
  getPropertySEODescription,
  PROPERTY_TYPES_BIBLIO as PT
} from 'services/seo'

import { getLinkConfigByProperty } from 'components/Vip/linkConfig'
import styles, {
  specificationsWithoutIconsStyle,
  specificationWithoutIconStyle
} from './styles'

const propertyTypesFind = propType => {
  const label = PT[propType]
  return label === 'Departamento' ? 'Depto.' : label
}

const getSpecificationTitle = (specificationType, count) => {
  const { srpText, srpTextSingular } = defaultConfig[specificationType]

  return count === 1 && srpTextSingular ? srpTextSingular : srpText
}

export const SimpleSpecification = ({ title, count }) => (
  <div className="specification">
    <span className="count">{count}</span>
    <span className="title">{title}</span>

    <style jsx>{specificationWithoutIconStyle}</style>
  </div>
)

export const SpecificationsWithoutIcons = ({
  className,
  bedrooms,
  bathrooms,
  parkingSpaces,
  surfaceArea
}) => (
  <section className={classnames('specifications', className)}>
    <SimpleSpecification
      count={bedrooms}
      title={getSpecificationTitle('bedrooms', bedrooms)}
    />
    <SimpleSpecification
      count={bathrooms}
      title={getSpecificationTitle('bathrooms', bathrooms)}
    />
    <SimpleSpecification
      count={parkingSpaces}
      title={getSpecificationTitle('parkingSpaces', parkingSpaces)}
    />
    <SimpleSpecification
      count={surfaceArea}
      title={getSpecificationTitle('surfaceArea', surfaceArea)}
    />
    <style jsx>{specificationsWithoutIconsStyle}</style>
  </section>
)

const SRPCard = ({
  loading,
  propertyData,
  style,
  className,
  isSmall,
  showTagOcupation,
  asTitleComponent = 'h2'
}) => {
  const { id, title, address, imagesJson, propertyType } = propertyData
  const currentOcupationStatus = new Property(propertyData).currentOcupationStatus()

  const { amount, currency } = propertyData.price || {}

  const firstImage = getFirstImageOriginalProperty(imagesJson)

  const propertySEODescription = getPropertySEODescription(propertyData)

  const linkConfig = getLinkConfigByProperty(propertyData)

  const TitleComponent = asTitleComponent

  return (
    <div
      className={classnames(
        'srp-card',
        { loading, 'is-small': isSmall },
        className
      )}
      style={style}
    >
      <div className="header">
          <a
            className="srp-card-title srp-card-link"
            title={propertySEODescription}
            target='_blank'
            href={linkConfig.as}
          >
            <TitleComponent
              className={classnames('srp-card-title-element', {
                'srp-card-ellipsis-text': isSmall
              })}
            >
              {PT[propertyType]} en {address}
            </TitleComponent>
          </a>

        <PictureCropped
          image={firstImage}
          width={350}
          height={isSmall ? 208 : 250}
          format="webp"
          alt={`NEX-${id} - ${propertySEODescription}`}
          className="srp-card-image picture-cropped-srp-image"
          onClick={() => window.open(linkConfig.as, "_blank")}
        />

        {showTagOcupation && (
          <TagOcupationStatus
            status={currentOcupationStatus}
            position="top-right"
          />
        )}
      </div>
      <div className="srp-card-content">
        {!isSmall && <h3 className="srp-card-address">{title}</h3>}

        <span className="price-property-type-wrapper">
          <Label
            color={isSmall ? 'primary' : 'text'}
            size="small"
            style={{ marginRight: '0.5rem' }}
          >
            {propertyTypesFind(propertyType)}
          </Label>

          <span className="srp-card-price">
            $ {formatCurrency(amount, '')} {currency}
          </span>

          {!isSmall && (
            <SpecificationsWithoutIcons
              {...propertyData}
              specificationType="home"
              className="srp-card-specifications"
            />
          )}
        </span>
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

SRPCard.propTypes = {
  isSmall: PropTypes.bool,
  loading: PropTypes.bool,
  propertyData: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
}

SRPCard.defaultProps = {
  propertyData: {
    price: {
      amount: 10000,
      currency: 'mxn'
    },
    address: 'Calzada Obrero Mundial 123, Narvarte, México',
    imagesJson: [],
    description: 'Bonita Casa',
    id: 2861,
    operationType: 'RENT',
    propertyType: 'HOUSE',
    state: 'México',
    bedrooms: 1,
    bathrooms: 2,
    parkingSpaces: 3,
    surfaceArea: 435
  }
}

export default SRPCard
