import { vipPathUrl, externalBrokerPathUrl } from 'services/seo'
import { PROFILE } from 'components/SEO/utils'

export const getLinkConfig = ({
  propertyData,
  title,
  propertyType,
  operationType,
  propertyId
}) => {
  if (propertyData.brokerData?.isSearchable === false) {
    /* if property is NOT searchable it should be to internal view */
    return {
      href: {
        pathname: '/dashboard/private-vip',
        query: { propertyId: propertyData.id },
      },
      as: `/dashboard/private-property/${propertyData.id}`
    }
  }

  return {
    href: {
      pathname: '/vip',
      query: {
        propertyId,
        param1: title,
        param2: propertyType,
        param3: operationType,
        param4: propertyData.brokerData?.isExternal ? externalBrokerPathUrl(PROFILE.fullName(propertyData.brokerData)) : null
      }
    },
    as: vipPathUrl(
      propertyData.propertyType,
      propertyData.operationType,
      propertyData.title,
      propertyData.id,
      propertyData.brokerData?.isExternal ? PROFILE.fullName(propertyData.brokerData) : null
    )
  }
}

export const getLinkConfigByProperty = propertyData => {
  const { title, propertyType, operationType, id } = propertyData

  return getLinkConfig({
    propertyData,
    title,
    propertyType,
    operationType,
    propertyId: id
  })
}

const VIP_ANALYTIC_ACTION_PATHS = {
  PDF_DOWNLOADED: 'pdf-downloaded',
  PROPERTY_INFO_REQUESTED: 'property-info-requested',
  BROKER_TELEPHONE_REQUESTED: 'broker-telephone-requested',
  PROPERTY_REPORTED: 'property-reported'
}

/**
 * @function isAVIPAction
 * @param {String} action - One of VIP_ANALYTIC_ACTION_PATHS
 * @returns {function}
 */
 function isAVIPAction(action) {
  return url => url.includes(action)
}

/**
 * @function isPropertyInfoRequestedAction
 * @param {String} url - Just the path (a.k.a 'asPath')
 * @returns {Boolean}
 */
export const isPropertyInfoRequestedAction = isAVIPAction(
  VIP_ANALYTIC_ACTION_PATHS.PROPERTY_INFO_REQUESTED,
)

function getCurrentSearchParams() {
  if (typeof window !== 'object') {
    return ""
  }

  return new URL(window.location).search
}

export const getAnalyticActionTrackerLink = (propertyData, analyticAction) => {
  const linkConfig = getLinkConfigByProperty(propertyData)
  const currentSearchParams = getCurrentSearchParams()

  linkConfig.as = `${linkConfig.as}/${VIP_ANALYTIC_ACTION_PATHS[analyticAction]}${currentSearchParams}`

  return linkConfig
}
