import css from 'styled-jsx/css'
import { colors, fontSizes } from 'theme'

export default css`
.header-control {
  width: 100%;
}

.select-container {
  margin: 0 0.5rem 0 0;
}
.margined-top {
  margin-top: 0.5rem;
}

.search-button {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 992px){
  .wrapper-address{
    margin: 0;
    padding: 0;
  }
}

.nexsite1-side-search{
  width: auto;
  padding:0;
  background:none;
}

.nexsite1-side-search :global(.control-filter p:first-child) {
  font-size:1rem;
  margin:0 0 1.25rem 0;
}

.nexsite1-side-search :global(.control-filter) {
  padding-bottom: 0.9375rem;
  border-bottom: 0.0625rem solid ${colors.border};
  margin-bottom: 1.25rem;
  min-width: 15.9375rem;
}

.nexsite1-side-search :global(.control-filter .control-filter){  
  padding-bottom: 0;
  border-bottom:0;
  margin-bottom:0;
}

@media screen and (max-width: 62rem){
  .filter-bin.nexsite1-side-search{
    display:block;
  }
}
`

export const filterList = css`
  .header-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-filter .title-filters {
    margin: 1.5rem 0 1rem;
    font-size: ${fontSizes.h5};
  }

  .header-filter.nexsite1-side-search {
    padding-bottom: 0.9375rem;
    border-bottom: 0.0625rem solid ${colors.border};
    margin-bottom: 1.25rem;
    min-width: 15.9375rem;
  }
  
  .header-filter.nexsite1-side-search .title-filters {
    font-size: 1rem;
    margin: 0 0 1.25rem 0;
  }
`
