import { createSelector } from 'reselect'
import { amenitiesToValues } from 'services/seo/amenities'

/**
 * @function amenitiesBy
 * @param {Object[]} amenities
 * @param {String} amenities.name
 * @param {String} amenities.type - 'AMENITY' | 'CHARACTERISTICS'
 * @param {String[]} amenities.operationType
 * @returns {Object}
 */
export const amenitiesBy = amenities => {
  const ALL = []

  const PROPERTY_TYPES = {
    APARTMENT: [],
    HOUSE: [],
    LOTS: [],
    ROOMS: [],
    STORE: [],
    WAREHOUSE: [],
    OFFICE: [],
    BUILDING: [],
    HOTEL: [],
    RANCH: []
  }

  const TYPE = {
    AMENITY: [],
    CHARACTERISTICS: []
  }

  for (const amenity of amenities) {
    const amenityValue = amenitiesToValues([amenity.name])

    const copyAmenity = {
      amenityValue,
      fieldName: Object.keys(amenityValue).shift() || null,
      ...amenity,
    }
    ALL.push(copyAmenity)
    if (copyAmenity.type === 'AMENITY') {
      TYPE.AMENITY.push(copyAmenity)
    } else if (copyAmenity.type === 'CHARACTERISTICS') {
      TYPE.AMENITY.push(copyAmenity)
    }

    copyAmenity.propertyTypes.forEach(operationType => {
      PROPERTY_TYPES[operationType]?.push(copyAmenity)
    })
  }

  return {
    ALL,
    PROPERTY_TYPES,
    TYPE
  }
}

export const amenitiesSelector = state =>
  state.store_publicAmenities.filter?.response || []

export const amenitiesBySelector = createSelector(
  amenitiesSelector,
  amenitiesBy
)
