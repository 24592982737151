import css from 'styled-jsx/css'
import { colors } from 'theme'

export const srpLink = css`
  .anchor {
    color: ${colors.text};
    font-size: 1rem;
    opacity: 0.8;
  }
  a.anchor:hover {
    color: ${colors.primary};
    opacity: 1;
    text-decoration: underline;
  }

  @media screen and (max-width: 992px) {
    .anchor {
      padding: 0.5rem;
    }

    :global(span.label.srp-link-label) {
      justify-content: flex-start;
    }
  }
`
