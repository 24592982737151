import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { calculateAsPathName } from './utils'
import { pageViewStyle } from './styles'

const ListItem = ({ children, selected }) => (
  <li
    className={classnames('nex-paginate-page', {
      'nex-paginate-page-active': selected
    })}
  >
    {children}
    <style jsx>{pageViewStyle}</style>
  </li>
)

const PageView = ({
  page,
  selected,
  urlPage,
  queryParams,
  urlParams,
  pathName,
  nameLabel
}) => {
  if (selected) {
    return (
      <ListItem selected={selected}>
        <span role="button" className="nex-paginate-link" tabIndex="0">
          {page}
          <style jsx>{pageViewStyle}</style>
        </span>
      </ListItem>
    )
  }

  return (
    <ListItem selected={selected}>
      <Link
        href={{
          pathname: urlPage,
          query: { ...queryParams, page }
        }}
        as={{
          pathname: calculateAsPathName({ pathName, nameLabel, page }),
          query: urlParams
        }}
      >
        <a role="button" className="nex-paginate-link" tabIndex="0">
          {page}
          <style jsx>{pageViewStyle}</style>
        </a>
      </Link>
    </ListItem>
  )
}

PageView.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  urlPage: PropTypes.string,
  queryParams: PropTypes.object,
  urlParams: PropTypes.object,
  pathName: PropTypes.string,
  nameLabe: PropTypes.string
}

export default PageView
