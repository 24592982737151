import React from 'react'
import PropTypes from 'prop-types'
import { useSrpParams } from 'app/hooks'
import Link from 'next/link'
import { numberOrBoolean } from 'app/services/number'
import getSrpLinkConfig from 'components/Footer/FooterFilters/getSrpLinkConfig'
import { Label } from 'controls'
import { srpLink } from './styles'

const SRPLinkLabel = ({ children, isActive }) => (
  <Label
    size="small"
    color={isActive ? 'primary' : 'tertiary'}
    style={{ margin: '0 0.5rem 0.5rem 0', minWidth: 'max-content' }}
    className="srp-link-label"
  >
    {children}
  </Label>
)

const SRPLink = ({
  title,
  label,
  isActive,
  linkConfig,
  wrapperLinkComponent = SRPLinkLabel
}) => {
  const WrapperLink = wrapperLinkComponent

  if (isActive) {
    return (
      <WrapperLink isActive>
        <span className="anchor">{label}</span>
      </WrapperLink>
    )
  }

  return (
    <WrapperLink>
        <a className="anchor" title={title || label} href={linkConfig?.as?.pathname} target="_blank">
          {label}
        </a>
      <style jsx>{srpLink}</style>
    </WrapperLink>
  )
}

SRPLink.propTypes = {
  title: PropTypes.string,
  label: PropTypes.any,
  isActive: PropTypes.bool,
  linkConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  wrapperLinkComponent: PropTypes.any, // If you need a custom wrapper component 
}

const SRPLinkWrapper = ({
  title,
  fieldName,
  currentValue,
  label,
  linkWrapperComponent = SRPLinkLabel
}) => {
  const srpParams = useSrpParams()

  const isActive = currentValue === numberOrBoolean(srpParams[fieldName])
  const linkConfig =
    !isActive &&
    getSrpLinkConfig({
      ...srpParams,
      [fieldName]: currentValue
    })

  return (
    <SRPLink
      title={title}
      label={label}
      isActive={isActive}
      linkConfig={linkConfig}
      wrapperLinkComponent={linkWrapperComponent}
    />
  )
}

export default SRPLinkWrapper
