import css from 'styled-jsx/css'
import { colors } from 'theme'

export const propertyFiltersStyles = css`
  .property-filters {
    padding: 0 1rem 1rem;
    background ${colors.tertiary};
    width: 15rem;
    position: sticky;
  }

  @media screen and (max-width: 992px){
    .property-filters {
      width: 100%;
      display: none;
    }
    .property-filters.active {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    .property-filters {
      padding-bottom: 4.5rem;
    }
  }
`

export const controlFilterStyles = css`
  .control-filter {
    display: flex;
    flex-flow: column;
  }
 
  .control-filter .wrapper-field {
    width: 100%;
  }

  .control-filter .wrapper-field .field {
    width: 100%;
    margin: 0.5rem 0;
  }
  .control-filter .wrapper-field .field:first-child,
  .control-filter .prices .field:first-child {
    margin-left: 0;
  }
  .control-filter .wrapper-field .field:last-child,
  .control-filter .prices .field:last-child {
    margin-right: 0;
  }
  .control-filter .prices .field{
    margin: 0.5rem 0;
  }

  @media screen and (max-width: 992px){
    .control-filter .wrapper-field {
      flex-direction: column;
    }
    .control-filter .wrapper-field .field,
    .control-filter .prices .field{
      margin: 0.5rem 0;
    }
    .control-filter .wrapper-field .field:first-child,
    .control-filter .prices .field:first-child {
      margin-left: inherit;
    }
    .control-filter .wrapper-field .field:last-child,
    .control-filter .prices .field:last-child {
      margin-right: inherit;
    }
    .control-filter .prices {
      display: flex;
      flex-direction: column;
    }
  }
`

export const filterList = css``
