export const NUMBER_FILTER = [
  {
    label: 'Todos',
    value: ''
  },
  {
    label: '1',
    value: '1'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '4',
    value: '4'
  },
  {
    label: '5 ó más',
    value: '5'
  }
]

export const mapToOptions = e => ({
  value: e.id,
  label: e.name,
  ...e
})

export const mapNeighborhoodsToOptions = e => ({
  value: e.id,
  label: `${e.name} - ${e.postalCode}`,
  ...e
})

export const sortOptions = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

export const getNexIds = id => {
  const filtersId = id && Array.isArray(id) ? id : [id]
  return filtersId.filter(v => v)
}

export const ACTIVE_PLANS_TYPES = [
  {
    label: 'Propiedades de Neximers',
    value: 'OTHER'
  },
  {
    label: 'Propiedades Externas',
    value: 'EXTERNAL_LITE'
  },
]

export const SALE_TYPES = [
  {
    label: 'Opción',
    value: 'OPEN'
  },
  {
    label: 'Exclusiva',
    value: 'EXCLUSIVE'
  },
]

export const IS_A_DEVELOPMENT_OPTIONS = [
  {
    label: 'Usado',
    value: 'FALSE'
  },
  {
    label: 'Desarrollo',
    value: 'TRUE'
  }
]

export const DEVELOPMENT_TYPES = [
  {
    label: 'Preventa',
    value: 'PRESALE'
  },
  {
    label: 'Entrega Inmediata',
    value: 'IMMEDIATE_DELIVERY'
  },
]

export const CURRENCY_OPTIONS = [
  {
    label: 'MXN',
    value: 'MXN'
  },
  {
    label: 'USD',
    value: 'USD'
  }
]
