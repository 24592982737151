import React from 'react'
import classnames from 'classnames'

import { navBarMobile, hamburgerMenu, navBar } from './styles'

export const NavBarContainer = ({
  fixed,
  overFixed,
  whiteNavBar,
  negativeMenuProps,
  negativeMenuState,

  blueNavBar,

  children
}) => {
  const negativeMenu = whiteNavBar || negativeMenuProps || negativeMenuState

  return (
    <div
      className={classnames('nav-bar-container', {
        negative: negativeMenu,
        blueNavBar,
        fixed,
        'over-fixed': overFixed
      })}
    >
      <div className="nav-bar-actions app-wrapper-container">
        {children}
      </div>
      <style jsx>{navBar}</style>
    </div>
  )
}

export const NavBarHamburgerContainer = ({
  whiteNavBar,
  negativeMenuProps,
  negativeMenuState,

  blueNavBar,

  children
}) => {
  const negativeMenu = whiteNavBar || negativeMenuProps || negativeMenuState

  return (
    <div
      className={classnames('nav-bar-container', {
        negative: negativeMenu,
        blueNavBar
      })}
    >
      {children}
      <style jsx>{navBarMobile}</style>
      <style jsx global>
        {hamburgerMenu}
      </style>
    </div>
  )
}
