import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useSrpParams } from 'app/hooks'
import { getTitleToSrpLink } from 'services/seo'
import { Label } from 'controls'

import { filtersPanelStyles } from './styles'
import SRPLink from 'components/Srp/SRPLink'

const FooterUnderHightlighLabel = ({ isActive, children }) => (
  <span
    className={classnames('under-hightligh-button', {
      'is-active': isActive
    })}
  >
    {children}
  </span>
)

export const OperationTypeLink = ({ value, label }) => (
  <SRPLink
    label={label}
    currentValue={value}
    fieldName="operationType"
    linkWrapperComponent={FooterUnderHightlighLabel}
  />
)

const FooterFilterLabel = ({ children, isActive }) => (
  <Label
    size="small"
    className="footer-filter-label"
    color={isActive ? 'primary' : 'none'}
  >
    {children}
  </Label>
)

export const PropertyTypeLink = ({ value }) => {
  const { operationType, location } = useSrpParams()

  const label = getTitleToSrpLink({
    propertyType: value,
    operationType,
    location
  })

  return (
    <SRPLink
      label={label}
      currentValue={value}
      fieldName="propertyType"
      linkWrapperComponent={FooterFilterLabel}
    />
  )
}

PropertyTypeLink.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export const FiltersPanel = ({ children }) => (
  <div className="filters-panel">
    {children}

    <style jsx>{filtersPanelStyles}</style>
  </div>
)
